/*!
 // DO NOT EDIT THIS FILE!! YOUR CHANGES WILL BE LOST ON THE NEXT BUILD IF YOU DO EDIT ANYTHING HERE 
 */
 *, *::before, *::after {
    box-sizing: border-box;
  }
  html {
    // font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
  }
  body {
    margin: 0;
    //font-family: 'Nunito', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    font-family: 'Nunito', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
  }
  [tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
  }
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  abbr[title], abbr[data-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
  }
  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }
  ol, ul, dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  ol ol, ul ul, ol ul, ul ol {
    margin-bottom: 0;
  }
  dt {
    font-weight: 700;
  }
  dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
  }
  blockquote {
    margin: 0 0 1rem;
  }
  b, strong {
    font-weight: bolder;
  }
  small {
    font-size: 80%;
  }
  sub, sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }
  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }
  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }
  a:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  a:not([href]):not([class]) {
    color: inherit;
    text-decoration: none;
  }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
  }
  pre, code, kbd, samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em;
  }
  pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
  }
  figure {
    margin: 0 0 1rem;
  }
  img {
    vertical-align: middle;
    border-style: none;
  }
  svg {
    overflow: hidden;
    vertical-align: middle;
  }
  table {
    border-collapse: collapse;
  }
  caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom;
  }
  th {
    text-align: inherit;
  }
  label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }
  button {
    border-radius: 0;
  }
  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }
  input, button, select, optgroup, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  button, input {
    overflow: visible;
  }
  button, select {
    text-transform: none;
  }
  [role="button"] {
    cursor: pointer;
  }
  select {
    word-wrap: normal;
  }
  button, [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: button;
  }
  button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
    cursor: pointer;
  }
  button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }
  input[type="radio"], input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
  }
  textarea {
    overflow: auto;
    resize: vertical;
  }
  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }
  legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
  }
  progress {
    vertical-align: baseline;
  }
  [type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }
  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }
  output {
    display: inline-block;
  }
  summary {
    display: list-item;
    cursor: pointer;
  }
  template {
    display: none;
  }
  [hidden] {
    display: none !important;
  }
  .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 576px) {
    .container, .container-sm {
      max-width: 540px;
    }
  }
  @media (min-width: 768px) {
    .container, .container-sm, .container-md {
      max-width: 720px;
    }
  }
  @media (min-width: 992px) {
    .container, .container-sm, .container-md, .container-lg {
      max-width: 960px;
    }
  }
  @media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
      max-width: 1140px;
    }
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }
  .no-gutters > .col, .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-first {
    order: -1;
  }
  .order-last {
    order: 13;
  }
  .order-0 {
    order: 0;
  }
  .order-1 {
    order: 1;
  }
  .order-2 {
    order: 2;
  }
  .order-3 {
    order: 3;
  }
  .order-4 {
    order: 4;
  }
  .order-5 {
    order: 5;
  }
  .order-6 {
    order: 6;
  }
  .order-7 {
    order: 7;
  }
  .order-8 {
    order: 8;
  }
  .order-9 {
    order: 9;
  }
  .order-10 {
    order: 10;
  }
  .order-11 {
    order: 11;
  }
  .order-12 {
    order: 12;
  }
  .offset-1 {
    margin-left: 8.33333%;
  }
  .offset-2 {
    margin-left: 16.66667%;
  }
  .offset-3 {
    margin-left: 25%;
  }
  .offset-4 {
    margin-left: 33.33333%;
  }
  .offset-5 {
    margin-left: 41.66667%;
  }
  .offset-6 {
    margin-left: 50%;
  }
  .offset-7 {
    margin-left: 58.33333%;
  }
  .offset-8 {
    margin-left: 66.66667%;
  }
  .offset-9 {
    margin-left: 75%;
  }
  .offset-10 {
    margin-left: 83.33333%;
  }
  .offset-11 {
    margin-left: 91.66667%;
  }
  @media (min-width: 576px) {
    .col-sm {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .row-cols-sm-1 > * {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .row-cols-sm-2 > * {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .row-cols-sm-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .row-cols-sm-4 > * {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .row-cols-sm-5 > * {
      flex: 0 0 20%;
      max-width: 20%;
    }
    .row-cols-sm-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-sm-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    .col-sm-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    .col-sm-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-sm-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-sm-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .col-sm-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    .col-sm-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-sm-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    .col-sm-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    .col-sm-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-sm-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    .col-sm-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    .col-sm-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-sm-first {
      order: -1;
    }
    .order-sm-last {
      order: 13;
    }
    .order-sm-0 {
      order: 0;
    }
    .order-sm-1 {
      order: 1;
    }
    .order-sm-2 {
      order: 2;
    }
    .order-sm-3 {
      order: 3;
    }
    .order-sm-4 {
      order: 4;
    }
    .order-sm-5 {
      order: 5;
    }
    .order-sm-6 {
      order: 6;
    }
    .order-sm-7 {
      order: 7;
    }
    .order-sm-8 {
      order: 8;
    }
    .order-sm-9 {
      order: 9;
    }
    .order-sm-10 {
      order: 10;
    }
    .order-sm-11 {
      order: 11;
    }
    .order-sm-12 {
      order: 12;
    }
    .offset-sm-0 {
      margin-left: 0;
    }
    .offset-sm-1 {
      margin-left: 8.33333%;
    }
    .offset-sm-2 {
      margin-left: 16.66667%;
    }
    .offset-sm-3 {
      margin-left: 25%;
    }
    .offset-sm-4 {
      margin-left: 33.33333%;
    }
    .offset-sm-5 {
      margin-left: 41.66667%;
    }
    .offset-sm-6 {
      margin-left: 50%;
    }
    .offset-sm-7 {
      margin-left: 58.33333%;
    }
    .offset-sm-8 {
      margin-left: 66.66667%;
    }
    .offset-sm-9 {
      margin-left: 75%;
    }
    .offset-sm-10 {
      margin-left: 83.33333%;
    }
    .offset-sm-11 {
      margin-left: 91.66667%;
    }
  }
  @media (min-width: 768px) {
    .col-md {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .row-cols-md-1 > * {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .row-cols-md-2 > * {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .row-cols-md-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .row-cols-md-4 > * {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .row-cols-md-5 > * {
      flex: 0 0 20%;
      max-width: 20%;
    }
    .row-cols-md-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-md-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    .col-md-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    .col-md-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-md-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-md-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .col-md-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    .col-md-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-md-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    .col-md-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    .col-md-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-md-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    .col-md-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    .col-md-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-md-first {
      order: -1;
    }
    .order-md-last {
      order: 13;
    }
    .order-md-0 {
      order: 0;
    }
    .order-md-1 {
      order: 1;
    }
    .order-md-2 {
      order: 2;
    }
    .order-md-3 {
      order: 3;
    }
    .order-md-4 {
      order: 4;
    }
    .order-md-5 {
      order: 5;
    }
    .order-md-6 {
      order: 6;
    }
    .order-md-7 {
      order: 7;
    }
    .order-md-8 {
      order: 8;
    }
    .order-md-9 {
      order: 9;
    }
    .order-md-10 {
      order: 10;
    }
    .order-md-11 {
      order: 11;
    }
    .order-md-12 {
      order: 12;
    }
    .offset-md-0 {
      margin-left: 0;
    }
    .offset-md-1 {
      margin-left: 8.33333%;
    }
    .offset-md-2 {
      margin-left: 16.66667%;
    }
    .offset-md-3 {
      margin-left: 25%;
    }
    .offset-md-4 {
      margin-left: 33.33333%;
    }
    .offset-md-5 {
      margin-left: 41.66667%;
    }
    .offset-md-6 {
      margin-left: 50%;
    }
    .offset-md-7 {
      margin-left: 58.33333%;
    }
    .offset-md-8 {
      margin-left: 66.66667%;
    }
    .offset-md-9 {
      margin-left: 75%;
    }
    .offset-md-10 {
      margin-left: 83.33333%;
    }
    .offset-md-11 {
      margin-left: 91.66667%;
    }
  }
  @media (min-width: 992px) {
    .col-lg {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .row-cols-lg-1 > * {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .row-cols-lg-2 > * {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .row-cols-lg-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .row-cols-lg-4 > * {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .row-cols-lg-5 > * {
      flex: 0 0 20%;
      max-width: 20%;
    }
    .row-cols-lg-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-lg-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    .col-lg-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    .col-lg-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-lg-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-lg-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .col-lg-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    .col-lg-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-lg-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    .col-lg-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    .col-lg-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-lg-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    .col-lg-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    .col-lg-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-lg-first {
      order: -1;
    }
    .order-lg-last {
      order: 13;
    }
    .order-lg-0 {
      order: 0;
    }
    .order-lg-1 {
      order: 1;
    }
    .order-lg-2 {
      order: 2;
    }
    .order-lg-3 {
      order: 3;
    }
    .order-lg-4 {
      order: 4;
    }
    .order-lg-5 {
      order: 5;
    }
    .order-lg-6 {
      order: 6;
    }
    .order-lg-7 {
      order: 7;
    }
    .order-lg-8 {
      order: 8;
    }
    .order-lg-9 {
      order: 9;
    }
    .order-lg-10 {
      order: 10;
    }
    .order-lg-11 {
      order: 11;
    }
    .order-lg-12 {
      order: 12;
    }
    .offset-lg-0 {
      margin-left: 0;
    }
    .offset-lg-1 {
      margin-left: 8.33333%;
    }
    .offset-lg-2 {
      margin-left: 16.66667%;
    }
    .offset-lg-3 {
      margin-left: 25%;
    }
    .offset-lg-4 {
      margin-left: 33.33333%;
    }
    .offset-lg-5 {
      margin-left: 41.66667%;
    }
    .offset-lg-6 {
      margin-left: 50%;
    }
    .offset-lg-7 {
      margin-left: 58.33333%;
    }
    .offset-lg-8 {
      margin-left: 66.66667%;
    }
    .offset-lg-9 {
      margin-left: 75%;
    }
    .offset-lg-10 {
      margin-left: 83.33333%;
    }
    .offset-lg-11 {
      margin-left: 91.66667%;
    }
  }
  @media (min-width: 1200px) {
    .col-xl {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .row-cols-xl-1 > * {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .row-cols-xl-2 > * {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .row-cols-xl-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .row-cols-xl-4 > * {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .row-cols-xl-5 > * {
      flex: 0 0 20%;
      max-width: 20%;
    }
    .row-cols-xl-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-xl-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    .col-xl-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    .col-xl-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-xl-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-xl-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .col-xl-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    .col-xl-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-xl-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    .col-xl-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    .col-xl-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-xl-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    .col-xl-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    .col-xl-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-xl-first {
      order: -1;
    }
    .order-xl-last {
      order: 13;
    }
    .order-xl-0 {
      order: 0;
    }
    .order-xl-1 {
      order: 1;
    }
    .order-xl-2 {
      order: 2;
    }
    .order-xl-3 {
      order: 3;
    }
    .order-xl-4 {
      order: 4;
    }
    .order-xl-5 {
      order: 5;
    }
    .order-xl-6 {
      order: 6;
    }
    .order-xl-7 {
      order: 7;
    }
    .order-xl-8 {
      order: 8;
    }
    .order-xl-9 {
      order: 9;
    }
    .order-xl-10 {
      order: 10;
    }
    .order-xl-11 {
      order: 11;
    }
    .order-xl-12 {
      order: 12;
    }
    .offset-xl-0 {
      margin-left: 0;
    }
    .offset-xl-1 {
      margin-left: 8.33333%;
    }
    .offset-xl-2 {
      margin-left: 16.66667%;
    }
    .offset-xl-3 {
      margin-left: 25%;
    }
    .offset-xl-4 {
      margin-left: 33.33333%;
    }
    .offset-xl-5 {
      margin-left: 41.66667%;
    }
    .offset-xl-6 {
      margin-left: 50%;
    }
    .offset-xl-7 {
      margin-left: 58.33333%;
    }
    .offset-xl-8 {
      margin-left: 66.66667%;
    }
    .offset-xl-9 {
      margin-left: 75%;
    }
    .offset-xl-10 {
      margin-left: 83.33333%;
    }
    .offset-xl-11 {
      margin-left: 91.66667%;
    }
  }
  .align-baseline {
    vertical-align: baseline !important;
  }
  .align-top {
    vertical-align: top !important;
  }
  .align-middle {
    vertical-align: middle !important;
  }
  .align-bottom {
    vertical-align: bottom !important;
  }
  .align-text-bottom {
    vertical-align: text-bottom !important;
  }
  .align-text-top {
    vertical-align: text-top !important;
  }
  .bg-primary {
    background-color: #007bff !important;
  }
  a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus {
    background-color: #0062cc !important;
  }
  .bg-secondary {
    background-color: #6c757d !important;
  }
  a.bg-secondary:hover, a.bg-secondary:focus, button.bg-secondary:hover, button.bg-secondary:focus {
    background-color: #545b62 !important;
  }
  .bg-success {
    background-color: #28a745 !important;
  }
  a.bg-success:hover, a.bg-success:focus, button.bg-success:hover, button.bg-success:focus {
    background-color: #1e7e34 !important;
  }
  .bg-info {
    background-color: #17a2b8 !important;
  }
  a.bg-info:hover, a.bg-info:focus, button.bg-info:hover, button.bg-info:focus {
    background-color: #117a8b !important;
  }
  .bg-warning {
    background-color: #ffc107 !important;
  }
  a.bg-warning:hover, a.bg-warning:focus, button.bg-warning:hover, button.bg-warning:focus {
    background-color: #d39e00 !important;
  }
  .bg-danger {
    background-color: #dc3545 !important;
  }
  a.bg-danger:hover, a.bg-danger:focus, button.bg-danger:hover, button.bg-danger:focus {
    background-color: #bd2130 !important;
  }
  .bg-light {
    background-color: #f8f9fa !important;
  }
  a.bg-light:hover, a.bg-light:focus, button.bg-light:hover, button.bg-light:focus {
    background-color: #dae0e5 !important;
  }
  .bg-dark {
    background-color: #343a40 !important;
  }
  a.bg-dark:hover, a.bg-dark:focus, button.bg-dark:hover, button.bg-dark:focus {
    background-color: #1d2124 !important;
  }
  .bg-white {
    background-color: #fff !important;
  }
  .bg-transparent {
    background-color: transparent !important;
  }
  .border {
    border: 1px solid #dee2e6 !important;
  }
  .border-top {
    border-top: 1px solid #dee2e6 !important;
  }
  .border-right {
    border-right: 1px solid #dee2e6 !important;
  }
  .border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
  }
  .border-left {
    border-left: 1px solid #dee2e6 !important;
  }
  .border-0 {
    border: 0 !important;
  }
  .border-top-0 {
    border-top: 0 !important;
  }
  .border-right-0 {
    border-right: 0 !important;
  }
  .border-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-left-0 {
    border-left: 0 !important;
  }
  .border-primary {
    border-color: #007bff !important;
  }
  .border-secondary {
    border-color: #6c757d !important;
  }
  .border-success {
    border-color: #28a745 !important;
  }
  .border-info {
    border-color: #17a2b8 !important;
  }
  .border-warning {
    border-color: #ffc107 !important;
  }
  .border-danger {
    border-color: #dc3545 !important;
  }
  .border-light {
    border-color: #f8f9fa !important;
  }
  .border-dark {
    border-color: #343a40 !important;
  }
  .border-white {
    border-color: #fff !important;
  }
  .rounded-sm {
    border-radius: 0.2rem !important;
  }
  .rounded {
    border-radius: 0.25rem !important;
  }
  .rounded-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-lg {
    border-radius: 0.3rem !important;
  }
  .rounded-circle {
    border-radius: 50% !important;
  }
  .rounded-pill {
    border-radius: 50rem !important;
  }
  .rounded-0 {
    border-radius: 0 !important;
  }
  .clearfix::after {
    display: block;
    clear: both;
    content: "";
  }
  .d-none {
    display: none !important;
  }
  .d-inline {
    display: inline !important;
  }
  .d-inline-block {
    display: inline-block !important;
  }
  .d-block {
    display: block !important;
  }
  .d-table {
    display: table !important;
  }
  .d-table-row {
    display: table-row !important;
  }
  .d-table-cell {
    display: table-cell !important;
  }
  .d-flex {
    display: flex !important;
  }
  .d-inline-flex {
    display: inline-flex !important;
  }
  @media (min-width: 576px) {
    .d-sm-none {
      display: none !important;
    }
    .d-sm-inline {
      display: inline !important;
    }
    .d-sm-inline-block {
      display: inline-block !important;
    }
    .d-sm-block {
      display: block !important;
    }
    .d-sm-table {
      display: table !important;
    }
    .d-sm-table-row {
      display: table-row !important;
    }
    .d-sm-table-cell {
      display: table-cell !important;
    }
    .d-sm-flex {
      display: flex !important;
    }
    .d-sm-inline-flex {
      display: inline-flex !important;
    }
  }
  @media (min-width: 768px) {
    .d-md-none {
      display: none !important;
    }
    .d-md-inline {
      display: inline !important;
    }
    .d-md-inline-block {
      display: inline-block !important;
    }
    .d-md-block {
      display: block !important;
    }
    .d-md-table {
      display: table !important;
    }
    .d-md-table-row {
      display: table-row !important;
    }
    .d-md-table-cell {
      display: table-cell !important;
    }
    .d-md-flex {
      display: flex !important;
    }
    .d-md-inline-flex {
      display: inline-flex !important;
    }
  }
  @media (min-width: 992px) {
    .d-lg-none {
      display: none !important;
    }
    .d-lg-inline {
      display: inline !important;
    }
    .d-lg-inline-block {
      display: inline-block !important;
    }
    .d-lg-block {
      display: block !important;
    }
    .d-lg-table {
      display: table !important;
    }
    .d-lg-table-row {
      display: table-row !important;
    }
    .d-lg-table-cell {
      display: table-cell !important;
    }
    .d-lg-flex {
      display: flex !important;
    }
    .d-lg-inline-flex {
      display: inline-flex !important;
    }
  }
  @media (min-width: 1200px) {
    .d-xl-none {
      display: none !important;
    }
    .d-xl-inline {
      display: inline !important;
    }
    .d-xl-inline-block {
      display: inline-block !important;
    }
    .d-xl-block {
      display: block !important;
    }
    .d-xl-table {
      display: table !important;
    }
    .d-xl-table-row {
      display: table-row !important;
    }
    .d-xl-table-cell {
      display: table-cell !important;
    }
    .d-xl-flex {
      display: flex !important;
    }
    .d-xl-inline-flex {
      display: inline-flex !important;
    }
  }
  @media print {
    .d-print-none {
      display: none !important;
    }
    .d-print-inline {
      display: inline !important;
    }
    .d-print-inline-block {
      display: inline-block !important;
    }
    .d-print-block {
      display: block !important;
    }
    .d-print-table {
      display: table !important;
    }
    .d-print-table-row {
      display: table-row !important;
    }
    .d-print-table-cell {
      display: table-cell !important;
    }
    .d-print-flex {
      display: flex !important;
    }
    .d-print-inline-flex {
      display: inline-flex !important;
    }
  }
  .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
  }
  .embed-responsive::before {
    display: block;
    content: "";
  }
  .embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  .embed-responsive-21by9::before {
    padding-top: 42.85714%;
  }
  .embed-responsive-16by9::before {
    padding-top: 56.25%;
  }
  .embed-responsive-4by3::before {
    padding-top: 75%;
  }
  .embed-responsive-1by1::before {
    padding-top: 100%;
  }
  .flex-row {
    flex-direction: row !important;
  }
  .flex-column {
    flex-direction: column !important;
  }
  .flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-wrap {
    flex-wrap: wrap !important;
  }
  .flex-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-fill {
    flex: 1 1 auto !important;
  }
  .flex-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-start {
    justify-content: flex-start !important;
  }
  .justify-content-end {
    justify-content: flex-end !important;
  }
  .justify-content-center {
    justify-content: center !important;
  }
  .justify-content-between {
    justify-content: space-between !important;
  }
  .justify-content-around {
    justify-content: space-around !important;
  }
  .align-items-start {
    align-items: flex-start !important;
  }
  .align-items-end {
    align-items: flex-end !important;
  }
  .align-items-center {
    align-items: center !important;
  }
  .align-items-baseline {
    align-items: baseline !important;
  }
  .align-items-stretch {
    align-items: stretch !important;
  }
  .align-content-start {
    align-content: flex-start !important;
  }
  .align-content-end {
    align-content: flex-end !important;
  }
  .align-content-center {
    align-content: center !important;
  }
  .align-content-between {
    align-content: space-between !important;
  }
  .align-content-around {
    align-content: space-around !important;
  }
  .align-content-stretch {
    align-content: stretch !important;
  }
  .align-self-auto {
    align-self: auto !important;
  }
  .align-self-start {
    align-self: flex-start !important;
  }
  .align-self-end {
    align-self: flex-end !important;
  }
  .align-self-center {
    align-self: center !important;
  }
  .align-self-baseline {
    align-self: baseline !important;
  }
  .align-self-stretch {
    align-self: stretch !important;
  }
  @media (min-width: 576px) {
    .flex-sm-row {
      flex-direction: row !important;
    }
    .flex-sm-column {
      flex-direction: column !important;
    }
    .flex-sm-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-sm-wrap {
      flex-wrap: wrap !important;
    }
    .flex-sm-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .flex-sm-fill {
      flex: 1 1 auto !important;
    }
    .flex-sm-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-sm-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-sm-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-sm-shrink-1 {
      flex-shrink: 1 !important;
    }
    .justify-content-sm-start {
      justify-content: flex-start !important;
    }
    .justify-content-sm-end {
      justify-content: flex-end !important;
    }
    .justify-content-sm-center {
      justify-content: center !important;
    }
    .justify-content-sm-between {
      justify-content: space-between !important;
    }
    .justify-content-sm-around {
      justify-content: space-around !important;
    }
    .align-items-sm-start {
      align-items: flex-start !important;
    }
    .align-items-sm-end {
      align-items: flex-end !important;
    }
    .align-items-sm-center {
      align-items: center !important;
    }
    .align-items-sm-baseline {
      align-items: baseline !important;
    }
    .align-items-sm-stretch {
      align-items: stretch !important;
    }
    .align-content-sm-start {
      align-content: flex-start !important;
    }
    .align-content-sm-end {
      align-content: flex-end !important;
    }
    .align-content-sm-center {
      align-content: center !important;
    }
    .align-content-sm-between {
      align-content: space-between !important;
    }
    .align-content-sm-around {
      align-content: space-around !important;
    }
    .align-content-sm-stretch {
      align-content: stretch !important;
    }
    .align-self-sm-auto {
      align-self: auto !important;
    }
    .align-self-sm-start {
      align-self: flex-start !important;
    }
    .align-self-sm-end {
      align-self: flex-end !important;
    }
    .align-self-sm-center {
      align-self: center !important;
    }
    .align-self-sm-baseline {
      align-self: baseline !important;
    }
    .align-self-sm-stretch {
      align-self: stretch !important;
    }
  }
  @media (min-width: 768px) {
    .flex-md-row {
      flex-direction: row !important;
    }
    .flex-md-column {
      flex-direction: column !important;
    }
    .flex-md-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-md-wrap {
      flex-wrap: wrap !important;
    }
    .flex-md-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .flex-md-fill {
      flex: 1 1 auto !important;
    }
    .flex-md-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-md-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-md-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-md-shrink-1 {
      flex-shrink: 1 !important;
    }
    .justify-content-md-start {
      justify-content: flex-start !important;
    }
    .justify-content-md-end {
      justify-content: flex-end !important;
    }
    .justify-content-md-center {
      justify-content: center !important;
    }
    .justify-content-md-between {
      justify-content: space-between !important;
    }
    .justify-content-md-around {
      justify-content: space-around !important;
    }
    .align-items-md-start {
      align-items: flex-start !important;
    }
    .align-items-md-end {
      align-items: flex-end !important;
    }
    .align-items-md-center {
      align-items: center !important;
    }
    .align-items-md-baseline {
      align-items: baseline !important;
    }
    .align-items-md-stretch {
      align-items: stretch !important;
    }
    .align-content-md-start {
      align-content: flex-start !important;
    }
    .align-content-md-end {
      align-content: flex-end !important;
    }
    .align-content-md-center {
      align-content: center !important;
    }
    .align-content-md-between {
      align-content: space-between !important;
    }
    .align-content-md-around {
      align-content: space-around !important;
    }
    .align-content-md-stretch {
      align-content: stretch !important;
    }
    .align-self-md-auto {
      align-self: auto !important;
    }
    .align-self-md-start {
      align-self: flex-start !important;
    }
    .align-self-md-end {
      align-self: flex-end !important;
    }
    .align-self-md-center {
      align-self: center !important;
    }
    .align-self-md-baseline {
      align-self: baseline !important;
    }
    .align-self-md-stretch {
      align-self: stretch !important;
    }
  }
  @media (min-width: 992px) {
    .flex-lg-row {
      flex-direction: row !important;
    }
    .flex-lg-column {
      flex-direction: column !important;
    }
    .flex-lg-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-lg-wrap {
      flex-wrap: wrap !important;
    }
    .flex-lg-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .flex-lg-fill {
      flex: 1 1 auto !important;
    }
    .flex-lg-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-lg-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-lg-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-lg-shrink-1 {
      flex-shrink: 1 !important;
    }
    .justify-content-lg-start {
      justify-content: flex-start !important;
    }
    .justify-content-lg-end {
      justify-content: flex-end !important;
    }
    .justify-content-lg-center {
      justify-content: center !important;
    }
    .justify-content-lg-between {
      justify-content: space-between !important;
    }
    .justify-content-lg-around {
      justify-content: space-around !important;
    }
    .align-items-lg-start {
      align-items: flex-start !important;
    }
    .align-items-lg-end {
      align-items: flex-end !important;
    }
    .align-items-lg-center {
      align-items: center !important;
    }
    .align-items-lg-baseline {
      align-items: baseline !important;
    }
    .align-items-lg-stretch {
      align-items: stretch !important;
    }
    .align-content-lg-start {
      align-content: flex-start !important;
    }
    .align-content-lg-end {
      align-content: flex-end !important;
    }
    .align-content-lg-center {
      align-content: center !important;
    }
    .align-content-lg-between {
      align-content: space-between !important;
    }
    .align-content-lg-around {
      align-content: space-around !important;
    }
    .align-content-lg-stretch {
      align-content: stretch !important;
    }
    .align-self-lg-auto {
      align-self: auto !important;
    }
    .align-self-lg-start {
      align-self: flex-start !important;
    }
    .align-self-lg-end {
      align-self: flex-end !important;
    }
    .align-self-lg-center {
      align-self: center !important;
    }
    .align-self-lg-baseline {
      align-self: baseline !important;
    }
    .align-self-lg-stretch {
      align-self: stretch !important;
    }
  }
  @media (min-width: 1200px) {
    .flex-xl-row {
      flex-direction: row !important;
    }
    .flex-xl-column {
      flex-direction: column !important;
    }
    .flex-xl-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-xl-wrap {
      flex-wrap: wrap !important;
    }
    .flex-xl-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .flex-xl-fill {
      flex: 1 1 auto !important;
    }
    .flex-xl-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-xl-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-xl-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-xl-shrink-1 {
      flex-shrink: 1 !important;
    }
    .justify-content-xl-start {
      justify-content: flex-start !important;
    }
    .justify-content-xl-end {
      justify-content: flex-end !important;
    }
    .justify-content-xl-center {
      justify-content: center !important;
    }
    .justify-content-xl-between {
      justify-content: space-between !important;
    }
    .justify-content-xl-around {
      justify-content: space-around !important;
    }
    .align-items-xl-start {
      align-items: flex-start !important;
    }
    .align-items-xl-end {
      align-items: flex-end !important;
    }
    .align-items-xl-center {
      align-items: center !important;
    }
    .align-items-xl-baseline {
      align-items: baseline !important;
    }
    .align-items-xl-stretch {
      align-items: stretch !important;
    }
    .align-content-xl-start {
      align-content: flex-start !important;
    }
    .align-content-xl-end {
      align-content: flex-end !important;
    }
    .align-content-xl-center {
      align-content: center !important;
    }
    .align-content-xl-between {
      align-content: space-between !important;
    }
    .align-content-xl-around {
      align-content: space-around !important;
    }
    .align-content-xl-stretch {
      align-content: stretch !important;
    }
    .align-self-xl-auto {
      align-self: auto !important;
    }
    .align-self-xl-start {
      align-self: flex-start !important;
    }
    .align-self-xl-end {
      align-self: flex-end !important;
    }
    .align-self-xl-center {
      align-self: center !important;
    }
    .align-self-xl-baseline {
      align-self: baseline !important;
    }
    .align-self-xl-stretch {
      align-self: stretch !important;
    }
  }
  .float-left {
    float: left !important;
  }
  .float-right {
    float: right !important;
  }
  .float-none {
    float: none !important;
  }
  @media (min-width: 576px) {
    .float-sm-left {
      float: left !important;
    }
    .float-sm-right {
      float: right !important;
    }
    .float-sm-none {
      float: none !important;
    }
  }
  @media (min-width: 768px) {
    .float-md-left {
      float: left !important;
    }
    .float-md-right {
      float: right !important;
    }
    .float-md-none {
      float: none !important;
    }
  }
  @media (min-width: 992px) {
    .float-lg-left {
      float: left !important;
    }
    .float-lg-right {
      float: right !important;
    }
    .float-lg-none {
      float: none !important;
    }
  }
  @media (min-width: 1200px) {
    .float-xl-left {
      float: left !important;
    }
    .float-xl-right {
      float: right !important;
    }
    .float-xl-none {
      float: none !important;
    }
  }
  .user-select-all {
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    -ms-user-select: all !important;
    user-select: all !important;
  }
  .user-select-auto {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    user-select: auto !important;
  }
  .user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }
  .overflow-auto {
    overflow: auto !important;
  }
  .overflow-hidden {
    overflow: hidden !important;
  }
  .position-static {
    position: static !important;
  }
  .position-relative {
    position: relative !important;
  }
  .position-absolute {
    position: absolute !important;
  }
  .position-fixed {
    position: fixed !important;
  }
  .position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
  .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .sticky-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  .sr-only-focusable:active, .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  .shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  .shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  .shadow-none {
    box-shadow: none !important;
  }
  .w-25 {
    width: 25% !important;
  }
  .w-50 {
    width: 50% !important;
  }
  .w-75 {
    width: 75% !important;
  }
  .w-100 {
    width: 100% !important;
  }
  .w-auto {
    width: auto !important;
  }
  .h-25 {
    height: 25% !important;
  }
  .h-50 {
    height: 50% !important;
  }
  .h-75 {
    height: 75% !important;
  }
  .h-100 {
    height: 100% !important;
  }
  .h-auto {
    height: auto !important;
  }
  .mw-100 {
    max-width: 100% !important;
  }
  .mh-100 {
    max-height: 100% !important;
  }
  .min-vw-100 {
    min-width: 100vw !important;
  }
  .min-vh-100 {
    min-height: 100vh !important;
  }
  .vw-100 {
    width: 100vw !important;
  }
  .vh-100 {
    height: 100vh !important;
  }
  .m-0 {
    margin: 0 !important;
  }
  .mt-0, .my-0 {
    margin-top: 0 !important;
  }
  .mr-0, .mx-0 {
    margin-right: 0 !important;
  }
  .mb-0, .my-0 {
    margin-bottom: 0 !important;
  }
  .ml-0, .mx-0 {
    margin-left: 0 !important;
  }
  .m-1 {
    margin: 0.25rem !important;
  }
  .mt-1, .my-1 {
    margin-top: 0.25rem !important;
  }
  .mr-1, .mx-1 {
    margin-right: 0.25rem !important;
  }
  .mb-1, .my-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-1, .mx-1 {
    margin-left: 0.25rem !important;
  }
  .m-2 {
    margin: 0.5rem !important;
  }
  .mt-2, .my-2 {
    margin-top: 0.5rem !important;
  }
  .mr-2, .mx-2 {
    margin-right: 0.5rem !important;
  }
  .mb-2, .my-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-2, .mx-2 {
    margin-left: 0.5rem !important;
  }
  .m-3 {
    margin: 1rem !important;
  }
  .mt-3, .my-3 {
    margin-top: 1rem !important;
  }
  .mr-3, .mx-3 {
    margin-right: 1rem !important;
  }
  .mb-3, .my-3 {
    margin-bottom: 1rem !important;
  }
  .ml-3, .mx-3 {
    margin-left: 1rem !important;
  }
  .m-4 {
    margin: 1.5rem !important;
  }
  .mt-4, .my-4 {
    margin-top: 1.5rem !important;
  }
  .mr-4, .mx-4 {
    margin-right: 1.5rem !important;
  }
  .mb-4, .my-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-4, .mx-4 {
    margin-left: 1.5rem !important;
  }
  .m-5 {
    margin: 3rem !important;
  }
  .mt-5, .my-5 {
    margin-top: 3rem !important;
  }
  .mr-5, .mx-5 {
    margin-right: 3rem !important;
  }
  .mb-5, .my-5 {
    margin-bottom: 3rem !important;
  }
  .ml-5, .mx-5 {
    margin-left: 3rem !important;
  }
  .p-0 {
    padding: 0 !important;
  }
  .pt-0, .py-0 {
    padding-top: 0 !important;
  }
  .pr-0, .px-0 {
    padding-right: 0 !important;
  }
  .pb-0, .py-0 {
    padding-bottom: 0 !important;
  }
  .pl-0, .px-0 {
    padding-left: 0 !important;
  }
  .p-1 {
    padding: 0.25rem !important;
  }
  .pt-1, .py-1 {
    padding-top: 0.25rem !important;
  }
  .pr-1, .px-1 {
    padding-right: 0.25rem !important;
  }
  .pb-1, .py-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-1, .px-1 {
    padding-left: 0.25rem !important;
  }
  .p-2 {
    padding: 0.5rem !important;
  }
  .pt-2, .py-2 {
    padding-top: 0.5rem !important;
  }
  .pr-2, .px-2 {
    padding-right: 0.5rem !important;
  }
  .pb-2, .py-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-2, .px-2 {
    padding-left: 0.5rem !important;
  }
  .p-3 {
    padding: 1rem !important;
  }
  .pt-3, .py-3 {
    padding-top: 1rem !important;
  }
  .pr-3, .px-3 {
    padding-right: 1rem !important;
  }
  .pb-3, .py-3 {
    padding-bottom: 1rem !important;
  }
  .pl-3, .px-3 {
    padding-left: 1rem !important;
  }
  .p-4 {
    padding: 1.5rem !important;
  }
  .pt-4, .py-4 {
    padding-top: 1.5rem !important;
  }
  .pr-4, .px-4 {
    padding-right: 1.5rem !important;
  }
  .pb-4, .py-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-4, .px-4 {
    padding-left: 1.5rem !important;
  }
  .p-5 {
    padding: 3rem !important;
  }
  .pt-5, .py-5 {
    padding-top: 3rem !important;
  }
  .pr-5, .px-5 {
    padding-right: 3rem !important;
  }
  .pb-5, .py-5 {
    padding-bottom: 3rem !important;
  }
  .pl-5, .px-5 {
    padding-left: 3rem !important;
  }
  .m-n1 {
    margin: -0.25rem !important;
  }
  .mt-n1, .my-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-n1, .mx-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-n1, .my-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-n1, .mx-n1 {
    margin-left: -0.25rem !important;
  }
  .m-n2 {
    margin: -0.5rem !important;
  }
  .mt-n2, .my-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-n2, .mx-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-n2, .my-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-n2, .mx-n2 {
    margin-left: -0.5rem !important;
  }
  .m-n3 {
    margin: -1rem !important;
  }
  .mt-n3, .my-n3 {
    margin-top: -1rem !important;
  }
  .mr-n3, .mx-n3 {
    margin-right: -1rem !important;
  }
  .mb-n3, .my-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-n3, .mx-n3 {
    margin-left: -1rem !important;
  }
  .m-n4 {
    margin: -1.5rem !important;
  }
  .mt-n4, .my-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-n4, .mx-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-n4, .my-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-n4, .mx-n4 {
    margin-left: -1.5rem !important;
  }
  .m-n5 {
    margin: -3rem !important;
  }
  .mt-n5, .my-n5 {
    margin-top: -3rem !important;
  }
  .mr-n5, .mx-n5 {
    margin-right: -3rem !important;
  }
  .mb-n5, .my-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-n5, .mx-n5 {
    margin-left: -3rem !important;
  }
  .m-auto {
    margin: auto !important;
  }
  .mt-auto, .my-auto {
    margin-top: auto !important;
  }
  .mr-auto, .mx-auto {
    margin-right: auto !important;
  }
  .mb-auto, .my-auto {
    margin-bottom: auto !important;
  }
  .ml-auto, .mx-auto {
    margin-left: auto !important;
  }
  @media (min-width: 576px) {
    .m-sm-0 {
      margin: 0 !important;
    }
    .mt-sm-0, .my-sm-0 {
      margin-top: 0 !important;
    }
    .mr-sm-0, .mx-sm-0 {
      margin-right: 0 !important;
    }
    .mb-sm-0, .my-sm-0 {
      margin-bottom: 0 !important;
    }
    .ml-sm-0, .mx-sm-0 {
      margin-left: 0 !important;
    }
    .m-sm-1 {
      margin: 0.25rem !important;
    }
    .mt-sm-1, .my-sm-1 {
      margin-top: 0.25rem !important;
    }
    .mr-sm-1, .mx-sm-1 {
      margin-right: 0.25rem !important;
    }
    .mb-sm-1, .my-sm-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-sm-1, .mx-sm-1 {
      margin-left: 0.25rem !important;
    }
    .m-sm-2 {
      margin: 0.5rem !important;
    }
    .mt-sm-2, .my-sm-2 {
      margin-top: 0.5rem !important;
    }
    .mr-sm-2, .mx-sm-2 {
      margin-right: 0.5rem !important;
    }
    .mb-sm-2, .my-sm-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-sm-2, .mx-sm-2 {
      margin-left: 0.5rem !important;
    }
    .m-sm-3 {
      margin: 1rem !important;
    }
    .mt-sm-3, .my-sm-3 {
      margin-top: 1rem !important;
    }
    .mr-sm-3, .mx-sm-3 {
      margin-right: 1rem !important;
    }
    .mb-sm-3, .my-sm-3 {
      margin-bottom: 1rem !important;
    }
    .ml-sm-3, .mx-sm-3 {
      margin-left: 1rem !important;
    }
    .m-sm-4 {
      margin: 1.5rem !important;
    }
    .mt-sm-4, .my-sm-4 {
      margin-top: 1.5rem !important;
    }
    .mr-sm-4, .mx-sm-4 {
      margin-right: 1.5rem !important;
    }
    .mb-sm-4, .my-sm-4 {
      margin-bottom: 1.5rem !important;
    }
    .ml-sm-4, .mx-sm-4 {
      margin-left: 1.5rem !important;
    }
    .m-sm-5 {
      margin: 3rem !important;
    }
    .mt-sm-5, .my-sm-5 {
      margin-top: 3rem !important;
    }
    .mr-sm-5, .mx-sm-5 {
      margin-right: 3rem !important;
    }
    .mb-sm-5, .my-sm-5 {
      margin-bottom: 3rem !important;
    }
    .ml-sm-5, .mx-sm-5 {
      margin-left: 3rem !important;
    }
    .p-sm-0 {
      padding: 0 !important;
    }
    .pt-sm-0, .py-sm-0 {
      padding-top: 0 !important;
    }
    .pr-sm-0, .px-sm-0 {
      padding-right: 0 !important;
    }
    .pb-sm-0, .py-sm-0 {
      padding-bottom: 0 !important;
    }
    .pl-sm-0, .px-sm-0 {
      padding-left: 0 !important;
    }
    .p-sm-1 {
      padding: 0.25rem !important;
    }
    .pt-sm-1, .py-sm-1 {
      padding-top: 0.25rem !important;
    }
    .pr-sm-1, .px-sm-1 {
      padding-right: 0.25rem !important;
    }
    .pb-sm-1, .py-sm-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-sm-1, .px-sm-1 {
      padding-left: 0.25rem !important;
    }
    .p-sm-2 {
      padding: 0.5rem !important;
    }
    .pt-sm-2, .py-sm-2 {
      padding-top: 0.5rem !important;
    }
    .pr-sm-2, .px-sm-2 {
      padding-right: 0.5rem !important;
    }
    .pb-sm-2, .py-sm-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-sm-2, .px-sm-2 {
      padding-left: 0.5rem !important;
    }
    .p-sm-3 {
      padding: 1rem !important;
    }
    .pt-sm-3, .py-sm-3 {
      padding-top: 1rem !important;
    }
    .pr-sm-3, .px-sm-3 {
      padding-right: 1rem !important;
    }
    .pb-sm-3, .py-sm-3 {
      padding-bottom: 1rem !important;
    }
    .pl-sm-3, .px-sm-3 {
      padding-left: 1rem !important;
    }
    .p-sm-4 {
      padding: 1.5rem !important;
    }
    .pt-sm-4, .py-sm-4 {
      padding-top: 1.5rem !important;
    }
    .pr-sm-4, .px-sm-4 {
      padding-right: 1.5rem !important;
    }
    .pb-sm-4, .py-sm-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-sm-4, .px-sm-4 {
      padding-left: 1.5rem !important;
    }
    .p-sm-5 {
      padding: 3rem !important;
    }
    .pt-sm-5, .py-sm-5 {
      padding-top: 3rem !important;
    }
    .pr-sm-5, .px-sm-5 {
      padding-right: 3rem !important;
    }
    .pb-sm-5, .py-sm-5 {
      padding-bottom: 3rem !important;
    }
    .pl-sm-5, .px-sm-5 {
      padding-left: 3rem !important;
    }
    .m-sm-n1 {
      margin: -0.25rem !important;
    }
    .mt-sm-n1, .my-sm-n1 {
      margin-top: -0.25rem !important;
    }
    .mr-sm-n1, .mx-sm-n1 {
      margin-right: -0.25rem !important;
    }
    .mb-sm-n1, .my-sm-n1 {
      margin-bottom: -0.25rem !important;
    }
    .ml-sm-n1, .mx-sm-n1 {
      margin-left: -0.25rem !important;
    }
    .m-sm-n2 {
      margin: -0.5rem !important;
    }
    .mt-sm-n2, .my-sm-n2 {
      margin-top: -0.5rem !important;
    }
    .mr-sm-n2, .mx-sm-n2 {
      margin-right: -0.5rem !important;
    }
    .mb-sm-n2, .my-sm-n2 {
      margin-bottom: -0.5rem !important;
    }
    .ml-sm-n2, .mx-sm-n2 {
      margin-left: -0.5rem !important;
    }
    .m-sm-n3 {
      margin: -1rem !important;
    }
    .mt-sm-n3, .my-sm-n3 {
      margin-top: -1rem !important;
    }
    .mr-sm-n3, .mx-sm-n3 {
      margin-right: -1rem !important;
    }
    .mb-sm-n3, .my-sm-n3 {
      margin-bottom: -1rem !important;
    }
    .ml-sm-n3, .mx-sm-n3 {
      margin-left: -1rem !important;
    }
    .m-sm-n4 {
      margin: -1.5rem !important;
    }
    .mt-sm-n4, .my-sm-n4 {
      margin-top: -1.5rem !important;
    }
    .mr-sm-n4, .mx-sm-n4 {
      margin-right: -1.5rem !important;
    }
    .mb-sm-n4, .my-sm-n4 {
      margin-bottom: -1.5rem !important;
    }
    .ml-sm-n4, .mx-sm-n4 {
      margin-left: -1.5rem !important;
    }
    .m-sm-n5 {
      margin: -3rem !important;
    }
    .mt-sm-n5, .my-sm-n5 {
      margin-top: -3rem !important;
    }
    .mr-sm-n5, .mx-sm-n5 {
      margin-right: -3rem !important;
    }
    .mb-sm-n5, .my-sm-n5 {
      margin-bottom: -3rem !important;
    }
    .ml-sm-n5, .mx-sm-n5 {
      margin-left: -3rem !important;
    }
    .m-sm-auto {
      margin: auto !important;
    }
    .mt-sm-auto, .my-sm-auto {
      margin-top: auto !important;
    }
    .mr-sm-auto, .mx-sm-auto {
      margin-right: auto !important;
    }
    .mb-sm-auto, .my-sm-auto {
      margin-bottom: auto !important;
    }
    .ml-sm-auto, .mx-sm-auto {
      margin-left: auto !important;
    }
  }
  @media (min-width: 768px) {
    .m-md-0 {
      margin: 0 !important;
    }
    .mt-md-0, .my-md-0 {
      margin-top: 0 !important;
    }
    .mr-md-0, .mx-md-0 {
      margin-right: 0 !important;
    }
    .mb-md-0, .my-md-0 {
      margin-bottom: 0 !important;
    }
    .ml-md-0, .mx-md-0 {
      margin-left: 0 !important;
    }
    .m-md-1 {
      margin: 0.25rem !important;
    }
    .mt-md-1, .my-md-1 {
      margin-top: 0.25rem !important;
    }
    .mr-md-1, .mx-md-1 {
      margin-right: 0.25rem !important;
    }
    .mb-md-1, .my-md-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-md-1, .mx-md-1 {
      margin-left: 0.25rem !important;
    }
    .m-md-2 {
      margin: 0.5rem !important;
    }
    .mt-md-2, .my-md-2 {
      margin-top: 0.5rem !important;
    }
    .mr-md-2, .mx-md-2 {
      margin-right: 0.5rem !important;
    }
    .mb-md-2, .my-md-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-md-2, .mx-md-2 {
      margin-left: 0.5rem !important;
    }
    .m-md-3 {
      margin: 1rem !important;
    }
    .mt-md-3, .my-md-3 {
      margin-top: 1rem !important;
    }
    .mr-md-3, .mx-md-3 {
      margin-right: 1rem !important;
    }
    .mb-md-3, .my-md-3 {
      margin-bottom: 1rem !important;
    }
    .ml-md-3, .mx-md-3 {
      margin-left: 1rem !important;
    }
    .m-md-4 {
      margin: 1.5rem !important;
    }
    .mt-md-4, .my-md-4 {
      margin-top: 1.5rem !important;
    }
    .mr-md-4, .mx-md-4 {
      margin-right: 1.5rem !important;
    }
    .mb-md-4, .my-md-4 {
      margin-bottom: 1.5rem !important;
    }
    .ml-md-4, .mx-md-4 {
      margin-left: 1.5rem !important;
    }
    .m-md-5 {
      margin: 3rem !important;
    }
    .mt-md-5, .my-md-5 {
      margin-top: 3rem !important;
    }
    .mr-md-5, .mx-md-5 {
      margin-right: 3rem !important;
    }
    .mb-md-5, .my-md-5 {
      margin-bottom: 3rem !important;
    }
    .ml-md-5, .mx-md-5 {
      margin-left: 3rem !important;
    }
    .p-md-0 {
      padding: 0 !important;
    }
    .pt-md-0, .py-md-0 {
      padding-top: 0 !important;
    }
    .pr-md-0, .px-md-0 {
      padding-right: 0 !important;
    }
    .pb-md-0, .py-md-0 {
      padding-bottom: 0 !important;
    }
    .pl-md-0, .px-md-0 {
      padding-left: 0 !important;
    }
    .p-md-1 {
      padding: 0.25rem !important;
    }
    .pt-md-1, .py-md-1 {
      padding-top: 0.25rem !important;
    }
    .pr-md-1, .px-md-1 {
      padding-right: 0.25rem !important;
    }
    .pb-md-1, .py-md-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-md-1, .px-md-1 {
      padding-left: 0.25rem !important;
    }
    .p-md-2 {
      padding: 0.5rem !important;
    }
    .pt-md-2, .py-md-2 {
      padding-top: 0.5rem !important;
    }
    .pr-md-2, .px-md-2 {
      padding-right: 0.5rem !important;
    }
    .pb-md-2, .py-md-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-md-2, .px-md-2 {
      padding-left: 0.5rem !important;
    }
    .p-md-3 {
      padding: 1rem !important;
    }
    .pt-md-3, .py-md-3 {
      padding-top: 1rem !important;
    }
    .pr-md-3, .px-md-3 {
      padding-right: 1rem !important;
    }
    .pb-md-3, .py-md-3 {
      padding-bottom: 1rem !important;
    }
    .pl-md-3, .px-md-3 {
      padding-left: 1rem !important;
    }
    .p-md-4 {
      padding: 1.5rem !important;
    }
    .pt-md-4, .py-md-4 {
      padding-top: 1.5rem !important;
    }
    .pr-md-4, .px-md-4 {
      padding-right: 1.5rem !important;
    }
    .pb-md-4, .py-md-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-md-4, .px-md-4 {
      padding-left: 1.5rem !important;
    }
    .p-md-5 {
      padding: 3rem !important;
    }
    .pt-md-5, .py-md-5 {
      padding-top: 3rem !important;
    }
    .pr-md-5, .px-md-5 {
      padding-right: 3rem !important;
    }
    .pb-md-5, .py-md-5 {
      padding-bottom: 3rem !important;
    }
    .pl-md-5, .px-md-5 {
      padding-left: 3rem !important;
    }
    .m-md-n1 {
      margin: -0.25rem !important;
    }
    .mt-md-n1, .my-md-n1 {
      margin-top: -0.25rem !important;
    }
    .mr-md-n1, .mx-md-n1 {
      margin-right: -0.25rem !important;
    }
    .mb-md-n1, .my-md-n1 {
      margin-bottom: -0.25rem !important;
    }
    .ml-md-n1, .mx-md-n1 {
      margin-left: -0.25rem !important;
    }
    .m-md-n2 {
      margin: -0.5rem !important;
    }
    .mt-md-n2, .my-md-n2 {
      margin-top: -0.5rem !important;
    }
    .mr-md-n2, .mx-md-n2 {
      margin-right: -0.5rem !important;
    }
    .mb-md-n2, .my-md-n2 {
      margin-bottom: -0.5rem !important;
    }
    .ml-md-n2, .mx-md-n2 {
      margin-left: -0.5rem !important;
    }
    .m-md-n3 {
      margin: -1rem !important;
    }
    .mt-md-n3, .my-md-n3 {
      margin-top: -1rem !important;
    }
    .mr-md-n3, .mx-md-n3 {
      margin-right: -1rem !important;
    }
    .mb-md-n3, .my-md-n3 {
      margin-bottom: -1rem !important;
    }
    .ml-md-n3, .mx-md-n3 {
      margin-left: -1rem !important;
    }
    .m-md-n4 {
      margin: -1.5rem !important;
    }
    .mt-md-n4, .my-md-n4 {
      margin-top: -1.5rem !important;
    }
    .mr-md-n4, .mx-md-n4 {
      margin-right: -1.5rem !important;
    }
    .mb-md-n4, .my-md-n4 {
      margin-bottom: -1.5rem !important;
    }
    .ml-md-n4, .mx-md-n4 {
      margin-left: -1.5rem !important;
    }
    .m-md-n5 {
      margin: -3rem !important;
    }
    .mt-md-n5, .my-md-n5 {
      margin-top: -3rem !important;
    }
    .mr-md-n5, .mx-md-n5 {
      margin-right: -3rem !important;
    }
    .mb-md-n5, .my-md-n5 {
      margin-bottom: -3rem !important;
    }
    .ml-md-n5, .mx-md-n5 {
      margin-left: -3rem !important;
    }
    .m-md-auto {
      margin: auto !important;
    }
    .mt-md-auto, .my-md-auto {
      margin-top: auto !important;
    }
    .mr-md-auto, .mx-md-auto {
      margin-right: auto !important;
    }
    .mb-md-auto, .my-md-auto {
      margin-bottom: auto !important;
    }
    .ml-md-auto, .mx-md-auto {
      margin-left: auto !important;
    }
  }
  @media (min-width: 992px) {
    .m-lg-0 {
      margin: 0 !important;
    }
    .mt-lg-0, .my-lg-0 {
      margin-top: 0 !important;
    }
    .mr-lg-0, .mx-lg-0 {
      margin-right: 0 !important;
    }
    .mb-lg-0, .my-lg-0 {
      margin-bottom: 0 !important;
    }
    .ml-lg-0, .mx-lg-0 {
      margin-left: 0 !important;
    }
    .m-lg-1 {
      margin: 0.25rem !important;
    }
    .mt-lg-1, .my-lg-1 {
      margin-top: 0.25rem !important;
    }
    .mr-lg-1, .mx-lg-1 {
      margin-right: 0.25rem !important;
    }
    .mb-lg-1, .my-lg-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-lg-1, .mx-lg-1 {
      margin-left: 0.25rem !important;
    }
    .m-lg-2 {
      margin: 0.5rem !important;
    }
    .mt-lg-2, .my-lg-2 {
      margin-top: 0.5rem !important;
    }
    .mr-lg-2, .mx-lg-2 {
      margin-right: 0.5rem !important;
    }
    .mb-lg-2, .my-lg-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-lg-2, .mx-lg-2 {
      margin-left: 0.5rem !important;
    }
    .m-lg-3 {
      margin: 1rem !important;
    }
    .mt-lg-3, .my-lg-3 {
      margin-top: 1rem !important;
    }
    .mr-lg-3, .mx-lg-3 {
      margin-right: 1rem !important;
    }
    .mb-lg-3, .my-lg-3 {
      margin-bottom: 1rem !important;
    }
    .ml-lg-3, .mx-lg-3 {
      margin-left: 1rem !important;
    }
    .m-lg-4 {
      margin: 1.5rem !important;
    }
    .mt-lg-4, .my-lg-4 {
      margin-top: 1.5rem !important;
    }
    .mr-lg-4, .mx-lg-4 {
      margin-right: 1.5rem !important;
    }
    .mb-lg-4, .my-lg-4 {
      margin-bottom: 1.5rem !important;
    }
    .ml-lg-4, .mx-lg-4 {
      margin-left: 1.5rem !important;
    }
    .m-lg-5 {
      margin: 3rem !important;
    }
    .mt-lg-5, .my-lg-5 {
      margin-top: 3rem !important;
    }
    .mr-lg-5, .mx-lg-5 {
      margin-right: 3rem !important;
    }
    .mb-lg-5, .my-lg-5 {
      margin-bottom: 3rem !important;
    }
    .ml-lg-5, .mx-lg-5 {
      margin-left: 3rem !important;
    }
    .p-lg-0 {
      padding: 0 !important;
    }
    .pt-lg-0, .py-lg-0 {
      padding-top: 0 !important;
    }
    .pr-lg-0, .px-lg-0 {
      padding-right: 0 !important;
    }
    .pb-lg-0, .py-lg-0 {
      padding-bottom: 0 !important;
    }
    .pl-lg-0, .px-lg-0 {
      padding-left: 0 !important;
    }
    .p-lg-1 {
      padding: 0.25rem !important;
    }
    .pt-lg-1, .py-lg-1 {
      padding-top: 0.25rem !important;
    }
    .pr-lg-1, .px-lg-1 {
      padding-right: 0.25rem !important;
    }
    .pb-lg-1, .py-lg-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-lg-1, .px-lg-1 {
      padding-left: 0.25rem !important;
    }
    .p-lg-2 {
      padding: 0.5rem !important;
    }
    .pt-lg-2, .py-lg-2 {
      padding-top: 0.5rem !important;
    }
    .pr-lg-2, .px-lg-2 {
      padding-right: 0.5rem !important;
    }
    .pb-lg-2, .py-lg-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-lg-2, .px-lg-2 {
      padding-left: 0.5rem !important;
    }
    .p-lg-3 {
      padding: 1rem !important;
    }
    .pt-lg-3, .py-lg-3 {
      padding-top: 1rem !important;
    }
    .pr-lg-3, .px-lg-3 {
      padding-right: 1rem !important;
    }
    .pb-lg-3, .py-lg-3 {
      padding-bottom: 1rem !important;
    }
    .pl-lg-3, .px-lg-3 {
      padding-left: 1rem !important;
    }
    .p-lg-4 {
      padding: 1.5rem !important;
    }
    .pt-lg-4, .py-lg-4 {
      padding-top: 1.5rem !important;
    }
    .pr-lg-4, .px-lg-4 {
      padding-right: 1.5rem !important;
    }
    .pb-lg-4, .py-lg-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-lg-4, .px-lg-4 {
      padding-left: 1.5rem !important;
    }
    .p-lg-5 {
      padding: 3rem !important;
    }
    .pt-lg-5, .py-lg-5 {
      padding-top: 3rem !important;
    }
    .pr-lg-5, .px-lg-5 {
      padding-right: 3rem !important;
    }
    .pb-lg-5, .py-lg-5 {
      padding-bottom: 3rem !important;
    }
    .pl-lg-5, .px-lg-5 {
      padding-left: 3rem !important;
    }
    .m-lg-n1 {
      margin: -0.25rem !important;
    }
    .mt-lg-n1, .my-lg-n1 {
      margin-top: -0.25rem !important;
    }
    .mr-lg-n1, .mx-lg-n1 {
      margin-right: -0.25rem !important;
    }
    .mb-lg-n1, .my-lg-n1 {
      margin-bottom: -0.25rem !important;
    }
    .ml-lg-n1, .mx-lg-n1 {
      margin-left: -0.25rem !important;
    }
    .m-lg-n2 {
      margin: -0.5rem !important;
    }
    .mt-lg-n2, .my-lg-n2 {
      margin-top: -0.5rem !important;
    }
    .mr-lg-n2, .mx-lg-n2 {
      margin-right: -0.5rem !important;
    }
    .mb-lg-n2, .my-lg-n2 {
      margin-bottom: -0.5rem !important;
    }
    .ml-lg-n2, .mx-lg-n2 {
      margin-left: -0.5rem !important;
    }
    .m-lg-n3 {
      margin: -1rem !important;
    }
    .mt-lg-n3, .my-lg-n3 {
      margin-top: -1rem !important;
    }
    .mr-lg-n3, .mx-lg-n3 {
      margin-right: -1rem !important;
    }
    .mb-lg-n3, .my-lg-n3 {
      margin-bottom: -1rem !important;
    }
    .ml-lg-n3, .mx-lg-n3 {
      margin-left: -1rem !important;
    }
    .m-lg-n4 {
      margin: -1.5rem !important;
    }
    .mt-lg-n4, .my-lg-n4 {
      margin-top: -1.5rem !important;
    }
    .mr-lg-n4, .mx-lg-n4 {
      margin-right: -1.5rem !important;
    }
    .mb-lg-n4, .my-lg-n4 {
      margin-bottom: -1.5rem !important;
    }
    .ml-lg-n4, .mx-lg-n4 {
      margin-left: -1.5rem !important;
    }
    .m-lg-n5 {
      margin: -3rem !important;
    }
    .mt-lg-n5, .my-lg-n5 {
      margin-top: -3rem !important;
    }
    .mr-lg-n5, .mx-lg-n5 {
      margin-right: -3rem !important;
    }
    .mb-lg-n5, .my-lg-n5 {
      margin-bottom: -3rem !important;
    }
    .ml-lg-n5, .mx-lg-n5 {
      margin-left: -3rem !important;
    }
    .m-lg-auto {
      margin: auto !important;
    }
    .mt-lg-auto, .my-lg-auto {
      margin-top: auto !important;
    }
    .mr-lg-auto, .mx-lg-auto {
      margin-right: auto !important;
    }
    .mb-lg-auto, .my-lg-auto {
      margin-bottom: auto !important;
    }
    .ml-lg-auto, .mx-lg-auto {
      margin-left: auto !important;
    }
  }
  @media (min-width: 1200px) {
    .m-xl-0 {
      margin: 0 !important;
    }
    .mt-xl-0, .my-xl-0 {
      margin-top: 0 !important;
    }
    .mr-xl-0, .mx-xl-0 {
      margin-right: 0 !important;
    }
    .mb-xl-0, .my-xl-0 {
      margin-bottom: 0 !important;
    }
    .ml-xl-0, .mx-xl-0 {
      margin-left: 0 !important;
    }
    .m-xl-1 {
      margin: 0.25rem !important;
    }
    .mt-xl-1, .my-xl-1 {
      margin-top: 0.25rem !important;
    }
    .mr-xl-1, .mx-xl-1 {
      margin-right: 0.25rem !important;
    }
    .mb-xl-1, .my-xl-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-xl-1, .mx-xl-1 {
      margin-left: 0.25rem !important;
    }
    .m-xl-2 {
      margin: 0.5rem !important;
    }
    .mt-xl-2, .my-xl-2 {
      margin-top: 0.5rem !important;
    }
    .mr-xl-2, .mx-xl-2 {
      margin-right: 0.5rem !important;
    }
    .mb-xl-2, .my-xl-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-xl-2, .mx-xl-2 {
      margin-left: 0.5rem !important;
    }
    .m-xl-3 {
      margin: 1rem !important;
    }
    .mt-xl-3, .my-xl-3 {
      margin-top: 1rem !important;
    }
    .mr-xl-3, .mx-xl-3 {
      margin-right: 1rem !important;
    }
    .mb-xl-3, .my-xl-3 {
      margin-bottom: 1rem !important;
    }
    .ml-xl-3, .mx-xl-3 {
      margin-left: 1rem !important;
    }
    .m-xl-4 {
      margin: 1.5rem !important;
    }
    .mt-xl-4, .my-xl-4 {
      margin-top: 1.5rem !important;
    }
    .mr-xl-4, .mx-xl-4 {
      margin-right: 1.5rem !important;
    }
    .mb-xl-4, .my-xl-4 {
      margin-bottom: 1.5rem !important;
    }
    .ml-xl-4, .mx-xl-4 {
      margin-left: 1.5rem !important;
    }
    .m-xl-5 {
      margin: 3rem !important;
    }
    .mt-xl-5, .my-xl-5 {
      margin-top: 3rem !important;
    }
    .mr-xl-5, .mx-xl-5 {
      margin-right: 3rem !important;
    }
    .mb-xl-5, .my-xl-5 {
      margin-bottom: 3rem !important;
    }
    .ml-xl-5, .mx-xl-5 {
      margin-left: 3rem !important;
    }
    .p-xl-0 {
      padding: 0 !important;
    }
    .pt-xl-0, .py-xl-0 {
      padding-top: 0 !important;
    }
    .pr-xl-0, .px-xl-0 {
      padding-right: 0 !important;
    }
    .pb-xl-0, .py-xl-0 {
      padding-bottom: 0 !important;
    }
    .pl-xl-0, .px-xl-0 {
      padding-left: 0 !important;
    }
    .p-xl-1 {
      padding: 0.25rem !important;
    }
    .pt-xl-1, .py-xl-1 {
      padding-top: 0.25rem !important;
    }
    .pr-xl-1, .px-xl-1 {
      padding-right: 0.25rem !important;
    }
    .pb-xl-1, .py-xl-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-xl-1, .px-xl-1 {
      padding-left: 0.25rem !important;
    }
    .p-xl-2 {
      padding: 0.5rem !important;
    }
    .pt-xl-2, .py-xl-2 {
      padding-top: 0.5rem !important;
    }
    .pr-xl-2, .px-xl-2 {
      padding-right: 0.5rem !important;
    }
    .pb-xl-2, .py-xl-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-xl-2, .px-xl-2 {
      padding-left: 0.5rem !important;
    }
    .p-xl-3 {
      padding: 1rem !important;
    }
    .pt-xl-3, .py-xl-3 {
      padding-top: 1rem !important;
    }
    .pr-xl-3, .px-xl-3 {
      padding-right: 1rem !important;
    }
    .pb-xl-3, .py-xl-3 {
      padding-bottom: 1rem !important;
    }
    .pl-xl-3, .px-xl-3 {
      padding-left: 1rem !important;
    }
    .p-xl-4 {
      padding: 1.5rem !important;
    }
    .pt-xl-4, .py-xl-4 {
      padding-top: 1.5rem !important;
    }
    .pr-xl-4, .px-xl-4 {
      padding-right: 1.5rem !important;
    }
    .pb-xl-4, .py-xl-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-xl-4, .px-xl-4 {
      padding-left: 1.5rem !important;
    }
    .p-xl-5 {
      padding: 3rem !important;
    }
    .pt-xl-5, .py-xl-5 {
      padding-top: 3rem !important;
    }
    .pr-xl-5, .px-xl-5 {
      padding-right: 3rem !important;
    }
    .pb-xl-5, .py-xl-5 {
      padding-bottom: 3rem !important;
    }
    .pl-xl-5, .px-xl-5 {
      padding-left: 3rem !important;
    }
    .m-xl-n1 {
      margin: -0.25rem !important;
    }
    .mt-xl-n1, .my-xl-n1 {
      margin-top: -0.25rem !important;
    }
    .mr-xl-n1, .mx-xl-n1 {
      margin-right: -0.25rem !important;
    }
    .mb-xl-n1, .my-xl-n1 {
      margin-bottom: -0.25rem !important;
    }
    .ml-xl-n1, .mx-xl-n1 {
      margin-left: -0.25rem !important;
    }
    .m-xl-n2 {
      margin: -0.5rem !important;
    }
    .mt-xl-n2, .my-xl-n2 {
      margin-top: -0.5rem !important;
    }
    .mr-xl-n2, .mx-xl-n2 {
      margin-right: -0.5rem !important;
    }
    .mb-xl-n2, .my-xl-n2 {
      margin-bottom: -0.5rem !important;
    }
    .ml-xl-n2, .mx-xl-n2 {
      margin-left: -0.5rem !important;
    }
    .m-xl-n3 {
      margin: -1rem !important;
    }
    .mt-xl-n3, .my-xl-n3 {
      margin-top: -1rem !important;
    }
    .mr-xl-n3, .mx-xl-n3 {
      margin-right: -1rem !important;
    }
    .mb-xl-n3, .my-xl-n3 {
      margin-bottom: -1rem !important;
    }
    .ml-xl-n3, .mx-xl-n3 {
      margin-left: -1rem !important;
    }
    .m-xl-n4 {
      margin: -1.5rem !important;
    }
    .mt-xl-n4, .my-xl-n4 {
      margin-top: -1.5rem !important;
    }
    .mr-xl-n4, .mx-xl-n4 {
      margin-right: -1.5rem !important;
    }
    .mb-xl-n4, .my-xl-n4 {
      margin-bottom: -1.5rem !important;
    }
    .ml-xl-n4, .mx-xl-n4 {
      margin-left: -1.5rem !important;
    }
    .m-xl-n5 {
      margin: -3rem !important;
    }
    .mt-xl-n5, .my-xl-n5 {
      margin-top: -3rem !important;
    }
    .mr-xl-n5, .mx-xl-n5 {
      margin-right: -3rem !important;
    }
    .mb-xl-n5, .my-xl-n5 {
      margin-bottom: -3rem !important;
    }
    .ml-xl-n5, .mx-xl-n5 {
      margin-left: -3rem !important;
    }
    .m-xl-auto {
      margin: auto !important;
    }
    .mt-xl-auto, .my-xl-auto {
      margin-top: auto !important;
    }
    .mr-xl-auto, .mx-xl-auto {
      margin-right: auto !important;
    }
    .mb-xl-auto, .my-xl-auto {
      margin-bottom: auto !important;
    }
    .ml-xl-auto, .mx-xl-auto {
      margin-left: auto !important;
    }
  }
  .stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0);
  }
  .text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  }
  .text-justify {
    text-align: justify !important;
  }
  .text-wrap {
    white-space: normal !important;
  }
  .text-nowrap {
    white-space: nowrap !important;
  }
  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .text-left {
    text-align: left !important;
  }
  .text-right {
    text-align: right !important;
  }
  .text-center {
    text-align: center !important;
  }
  @media (min-width: 576px) {
    .text-sm-left {
      text-align: left !important;
    }
    .text-sm-right {
      text-align: right !important;
    }
    .text-sm-center {
      text-align: center !important;
    }
  }
  @media (min-width: 768px) {
    .text-md-left {
      text-align: left !important;
    }
    .text-md-right {
      text-align: right !important;
    }
    .text-md-center {
      text-align: center !important;
    }
  }
  @media (min-width: 992px) {
    .text-lg-left {
      text-align: left !important;
    }
    .text-lg-right {
      text-align: right !important;
    }
    .text-lg-center {
      text-align: center !important;
    }
  }
  @media (min-width: 1200px) {
    .text-xl-left {
      text-align: left !important;
    }
    .text-xl-right {
      text-align: right !important;
    }
    .text-xl-center {
      text-align: center !important;
    }
  }
  .text-lowercase {
    text-transform: lowercase !important;
  }
  .text-uppercase {
    text-transform: uppercase !important;
  }
  .text-capitalize {
    text-transform: capitalize !important;
  }
  .font-weight-light {
    font-weight: 300 !important;
  }
  .font-weight-lighter {
    font-weight: lighter !important;
  }
  .font-weight-normal {
    font-weight: 400 !important;
  }
  .font-weight-bold {
    font-weight: 700 !important;
  }
  .font-weight-bolder {
    font-weight: bolder !important;
  }
  .font-italic {
    font-style: italic !important;
  }
  .text-white {
    color: #fff !important;
  }
  .text-primary {
    color: #007bff !important;
  }
  a.text-primary:hover, a.text-primary:focus {
    color: #0056b3 !important;
  }
  .text-secondary {
    color: #6c757d !important;
  }
  a.text-secondary:hover, a.text-secondary:focus {
    color: #494f54 !important;
  }
  .text-success {
    color: #28a745 !important;
  }
  a.text-success:hover, a.text-success:focus {
    color: #19692c !important;
  }
  .text-info {
    color: #17a2b8 !important;
  }
  a.text-info:hover, a.text-info:focus {
    color: #0f6674 !important;
  }
  .text-warning {
    color: #ffc107 !important;
  }
  a.text-warning:hover, a.text-warning:focus {
    color: #ba8b00 !important;
  }
  .text-danger {
    color: #dc3545 !important;
  }
  a.text-danger:hover, a.text-danger:focus {
    color: #a71d2a !important;
  }
  .text-light {
    color: #f8f9fa !important;
  }
  a.text-light:hover, a.text-light:focus {
    color: #cbd3da !important;
  }
  .text-dark {
    color: #343a40 !important;
  }
  a.text-dark:hover, a.text-dark:focus {
    color: #121416 !important;
  }
  .text-body {
    color: #212529 !important;
  }
  .text-muted {
    color: #6c757d !important;
  }
  .text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important;
  }
  .text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
  }
  .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  .text-decoration-none {
    text-decoration: none !important;
  }
  .text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important;
  }
  .text-reset {
    color: inherit !important;
  }
  .visible {
    visibility: visible !important;
  }
  .invisible {
    visibility: hidden !important;
  }
  .carousel {
    position: relative;
  }
  .carousel.pointer-event {
    touch-action: pan-y;
  }
  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: "";
  }
  .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none;
    }
  }
  .carousel-item.active, .carousel-item-next, .carousel-item-prev {
    display: block;
  }
  .carousel-item-next:not(.carousel-item-left), .active.carousel-item-right {
    transform: translateX(100%);
  }
  .carousel-item-prev:not(.carousel-item-right), .active.carousel-item-left {
    transform: translateX(-100%);
  }
  .carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none;
  }
  .carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-left, .carousel-fade .carousel-item-prev.carousel-item-right {
    z-index: 1;
    opacity: 1;
  }
  .carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s 0.6s;
  }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
      transition: none;
    }
  }
  .carousel-control-prev, .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev, .carousel-control-next {
      transition: none;
    }
  }
  .carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
  }
  .carousel-control-prev {
    left: 0;
  }
  .carousel-control-next {
    right: 0;
  }
  .carousel-control-prev-icon, .carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50% / 100% 100%;
  }
  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
  }
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
  }
  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -40px;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
  }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .carousel-indicators li {
      transition: none;
    }
  }
  .carousel-indicators .active {
    opacity: 1;
  }
  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
  }
  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none;
    }
  }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0;
  }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057;
  }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  .form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1;
  }
  .form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
  }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1;
  }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
  }
  input[type="date"].form-control, input[type="time"].form-control, input[type="datetime-local"].form-control, input[type="month"].form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #fff;
  }
  .form-control-file, .form-control-range {
    display: block;
    width: 100%;
  }
  .col-form-label {
    padding-top: calc(.375rem + 1px);
    padding-bottom: calc(.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
  }
  .col-form-label-lg {
    padding-top: calc(.5rem + 1px);
    padding-bottom: calc(.5rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5;
  }
  .col-form-label-sm {
    padding-top: calc(.25rem + 1px);
    padding-bottom: calc(.25rem + 1px);
    font-size: 0.875rem;
    line-height: 1.5;
  }
  .form-control-plaintext {
    display: block;
    width: 100%;
    padding: 0.375rem 0;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
  }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0;
  }
  .form-control-sm {
    height: calc(1.5em + .5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
  .form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
  }
  select.form-control[size], select.form-control[multiple] {
    height: auto;
  }
  textarea.form-control {
    height: auto;
  }
  .form-group {
    margin-bottom: 1rem;
  }
  .form-text {
    display: block;
    margin-top: 0.25rem;
  }
  .form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
  }
  .form-row > .col, .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
  }
  .form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
  }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    color: #6c757d;
  }
  .form-check-label {
    margin-bottom: 0;
  }
  .form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
  }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
  }
  .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #28a745;
  }
  .valid-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(40, 167, 69, 0.9);
    border-radius: 0.25rem;
  }
  .was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip, .is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip {
    display: block;
  }
  .was-validated .form-control:valid, .form-control.is-valid {
    border-color: #28a745;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  }
  .was-validated textarea.form-control:valid, textarea.form-control.is-valid {
    padding-right: calc(1.5em + .75rem);
    background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem);
  }
  .was-validated .custom-select:valid, .custom-select.is-valid {
    border-color: #28a745;
    padding-right: calc(.75em + 2.3125rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem);
  }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #28a745;
  }
  .was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback, .form-check-input.is-valid ~ .valid-tooltip {
    display: block;
  }
  .was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
    color: #28a745;
  }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745;
  }
  .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
    border-color: #34ce57;
    background-color: #34ce57;
  }
  .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  }
  .was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #28a745;
  }
  .was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
    border-color: #28a745;
  }
  .was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  }
  .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
  .invalid-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(220, 53, 69, 0.9);
    border-radius: 0.25rem;
  }
  .was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
    display: block;
  }
  .was-validated .form-control:invalid, .form-control.is-invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
  .was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
    padding-right: calc(1.5em + .75rem);
    background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem);
  }
  .was-validated .custom-select:invalid, .custom-select.is-invalid {
    border-color: #dc3545;
    padding-right: calc(.75em + 2.3125rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem);
  }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #dc3545;
  }
  .was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-tooltip {
    display: block;
  }
  .was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
    color: #dc3545;
  }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545;
  }
  .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
    border-color: #e4606d;
    background-color: #e4606d;
  }
  .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
  .was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #dc3545;
  }
  .was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
    border-color: #dc3545;
  }
  .was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
  .form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
  .form-inline .form-check {
    width: 100%;
  }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
    }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0;
    }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }
    .form-inline .form-control-plaintext {
      display: inline-block;
    }
    .form-inline .input-group, .form-inline .custom-select {
      width: auto;
    }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0;
    }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0;
    }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center;
    }
    .form-inline .custom-control-label {
      margin-bottom: 0;
    }
  }
  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
  }
  .input-group > .form-control, .input-group > .form-control-plaintext, .input-group > .custom-select, .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
  }
  .input-group > .form-control + .form-control, .input-group > .form-control + .custom-select, .input-group > .form-control + .custom-file, .input-group > .form-control-plaintext + .form-control, .input-group > .form-control-plaintext + .custom-select, .input-group > .form-control-plaintext + .custom-file, .input-group > .custom-select + .form-control, .input-group > .custom-select + .custom-select, .input-group > .custom-select + .custom-file, .input-group > .custom-file + .form-control, .input-group > .custom-file + .custom-select, .input-group > .custom-file + .custom-file {
    margin-left: -1px;
  }
  .input-group > .form-control:focus, .input-group > .custom-select:focus, .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3;
  }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4;
  }
  .input-group > .form-control:not(:last-child), .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group > .form-control:not(:first-child), .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group > .custom-file {
    display: flex;
    align-items: center;
  }
  .input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group > .custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-prepend, .input-group-append {
    display: flex;
  }
  .input-group-prepend .btn, .input-group-append .btn {
    position: relative;
    z-index: 2;
  }
  .input-group-prepend .btn:focus, .input-group-append .btn:focus {
    z-index: 3;
  }
  .input-group-prepend .btn + .btn, .input-group-prepend .btn + .input-group-text, .input-group-prepend .input-group-text + .input-group-text, .input-group-prepend .input-group-text + .btn, .input-group-append .btn + .btn, .input-group-append .btn + .input-group-text, .input-group-append .input-group-text + .input-group-text, .input-group-append .input-group-text + .btn {
    margin-left: -1px;
  }
  .input-group-prepend {
    margin-right: -1px;
  }
  .input-group-append {
    margin-left: -1px;
  }
  .input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  .input-group-text input[type="radio"], .input-group-text input[type="checkbox"] {
    margin-top: 0;
  }
  .input-group-lg > .form-control:not(textarea), .input-group-lg > .custom-select {
    height: calc(1.5em + 1rem + 2px);
  }
  .input-group-lg > .form-control, .input-group-lg > .custom-select, .input-group-lg > .input-group-prepend > .input-group-text, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-append > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
  }
  .input-group-sm > .form-control:not(textarea), .input-group-sm > .custom-select {
    height: calc(1.5em + .5rem + 2px);
  }
  .input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
  .input-group-lg > .custom-select, .input-group-sm > .custom-select {
    padding-right: 1.75rem;
  }
  .input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text, .input-group > .input-group-append:not(:last-child) > .btn, .input-group > .input-group-append:not(:last-child) > .input-group-text, .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .dropup, .dropright, .dropdown, .dropleft {
    position: relative;
  }
  .dropdown-toggle {
    white-space: nowrap;
  }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
  }
  .dropdown-menu-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  @media (min-width: 576px) {
    .dropdown-menu-sm-left {
      right: auto;
      left: 0;
    }
    .dropdown-menu-sm-right {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 768px) {
    .dropdown-menu-md-left {
      right: auto;
      left: 0;
    }
    .dropdown-menu-md-right {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 992px) {
    .dropdown-menu-lg-left {
      right: auto;
      left: 0;
    }
    .dropdown-menu-lg-right {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 1200px) {
    .dropdown-menu-xl-left {
      right: auto;
      left: 0;
    }
    .dropdown-menu-xl-right {
      right: 0;
      left: auto;
    }
  }
  .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
  }
  .dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
  }
  .dropup .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
  }
  .dropright .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
  }
  .dropright .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropright .dropdown-toggle::after {
    vertical-align: 0;
  }
  .dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
  }
  .dropleft .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
  }
  .dropleft .dropdown-toggle::after {
    display: none;
  }
  .dropleft .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
  }
  .dropleft .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropleft .dropdown-toggle::before {
    vertical-align: 0;
  }
  .dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
    right: auto;
    bottom: auto;
  }
  .dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
  }
  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
  }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff;
  }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent;
  }
  .dropdown-menu.show {
    display: block;
  }
  .dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #6c757d;
    white-space: nowrap;
  }
  .dropdown-item-text {
    display: block;
    padding: 0.25rem 1.5rem;
    color: #212529;
  }
  .btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none;
    }
  }
  .btn:hover {
    color: #212529;
    text-decoration: none;
  }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  .btn.disabled, .btn:disabled {
    opacity: 0.65;
  }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  a.btn.disabled, fieldset:disabled a.btn {
    pointer-events: none;
  }
  .btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
  }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
  }
  .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  }
  .btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
  }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
  }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b;
  }
  .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
  }
  .btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
  }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
  }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430;
  }
  .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
  }
  .btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
  }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
  }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f;
  }
  .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
  }
  .btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
  }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
  }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500;
  }
  .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
  }
  .btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
  }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
  }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d;
  }
  .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
  }
  .btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
  }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
  }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df;
  }
  .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
  }
  .btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
  }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
  }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
  }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
  }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d;
  }
  .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
  }
  .btn-outline-primary {
    color: #007bff;
    border-color: #007bff;
  }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent;
  }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  }
  .btn-outline-secondary {
    color: #6c757d;
    border-color: #6c757d;
  }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent;
  }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
  .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  }
  .btn-outline-success {
    color: #28a745;
    border-color: #28a745;
  }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent;
  }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  }
  .btn-outline-info {
    color: #17a2b8;
    border-color: #17a2b8;
  }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent;
  }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  }
  .btn-outline-warning {
    color: #ffc107;
    border-color: #ffc107;
  }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent;
  }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  }
  .btn-outline-danger {
    color: #dc3545;
    border-color: #dc3545;
  }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent;
  }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  }
  .btn-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa;
  }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent;
  }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
  .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  }
  .btn-outline-dark {
    color: #343a40;
    border-color: #343a40;
  }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
  }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent;
  }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
  }
  .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  }
  .btn-link {
    font-weight: 400;
    color: #007bff;
    text-decoration: none;
  }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
  }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none;
  }
  .btn-lg {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
  }
  .btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
  .btn-block {
    display: block;
    width: 100%;
  }
  .btn-block + .btn-block {
    margin-top: 0.5rem;
  }
  input[type="submit"].btn-block, input[type="reset"].btn-block, input[type="button"].btn-block {
    width: 100%;
  }
  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
  }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none;
  }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default;
  }
  .nav-tabs {
    border-bottom: 1px solid #dee2e6;
  }
  .nav-tabs .nav-item {
    margin-bottom: -1px;
  }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6;
  }
  .nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
  }
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
  }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .nav-pills .nav-link {
    border-radius: 0.25rem;
  }
  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #007bff;
  }
  .nav-fill > .nav-link, .nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }
  .nav-justified > .nav-link, .nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
  .tab-content > .tab-pane {
    display: none;
  }
  .tab-content > .active {
    display: block;
  }
  .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
  }
  .navbar .container, .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  .navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
  }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none;
  }
  .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  .navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
  }
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none;
  }
  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
  }
  @media (max-width: 575.98px) {
    .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      padding-right: 0;
      padding-left: 0;
    }
  }
  @media (min-width: 576px) {
    .navbar-expand-sm {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-sm .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap;
    }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-sm .navbar-toggler {
      display: none;
    }
  }
  @media (max-width: 767.98px) {
    .navbar-expand-md > .container, .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      padding-right: 0;
      padding-left: 0;
    }
  }
  @media (min-width: 768px) {
    .navbar-expand-md {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-md .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-md .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-md > .container, .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap;
    }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-md .navbar-toggler {
      display: none;
    }
  }
  @media (max-width: 991.98px) {
    .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      padding-right: 0;
      padding-left: 0;
    }
  }
  @media (min-width: 992px) {
    .navbar-expand-lg {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap;
    }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-lg .navbar-toggler {
      display: none;
    }
  }
  @media (max-width: 1199.98px) {
    .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      padding-right: 0;
      padding-left: 0;
    }
  }
  @media (min-width: 1200px) {
    .navbar-expand-xl {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap;
    }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-xl .navbar-toggler {
      display: none;
    }
  }
  .navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand > .container, .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand > .container, .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand .navbar-toggler {
    display: none;
  }
  .navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
  }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9);
  }
  .navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5);
  }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7);
  }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
  }
  .navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9);
  }
  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280,0,0,0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
  .navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5);
  }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9);
  }
  .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9);
  }
  .navbar-dark .navbar-brand {
    color: #fff;
  }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff;
  }
  .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5);
  }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75);
  }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
  }
  .navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active {
    color: #fff;
  }
  .navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
  }
  .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255,255,255,0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
  .navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5);
  }
  .navbar-dark .navbar-text a {
    color: #fff;
  }
  .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
    color: #fff;
  }
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
  }
  .card > hr {
    margin-right: 0;
    margin-left: 0;
  }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit;
  }
  .card > .list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px);
  }
  .card > .list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px);
  }
  .card > .card-header + .list-group, .card > .list-group + .card-footer {
    border-top: 0;
  }
  .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
  }
  .card-title {
    margin-bottom: 0.75rem;
  }
  .card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
  }
  .card-text:last-child {
    margin-bottom: 0;
  }
  .card-link:hover {
    text-decoration: none;
  }
  .card-link + .card-link {
    margin-left: 1.25rem;
  }
  .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
  .card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
  }
  .card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }
  .card-footer:last-child {
    border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px);
  }
  .card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
  }
  .card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
  .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
    border-radius: calc(.25rem - 1px);
  }
  .card-img, .card-img-top, .card-img-bottom {
    flex-shrink: 0;
    width: 100%;
  }
  .card-img, .card-img-top {
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px);
  }
  .card-img, .card-img-bottom {
    border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px);
  }
  .card-deck .card {
    margin-bottom: 15px;
  }
  @media (min-width: 576px) {
    .card-deck {
      display: flex;
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px;
    }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px;
    }
  }
  .card-group > .card {
    margin-bottom: 15px;
  }
  @media (min-width: 576px) {
    .card-group {
      display: flex;
      flex-flow: row wrap;
    }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0;
    }
    .card-group > .card + .card {
      margin-left: 0;
      border-left: 0;
    }
    .card-group > .card:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-img-top, .card-group > .card:not(:last-child) .card-header {
      border-top-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card:not(:last-child) .card-footer {
      border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-img-top, .card-group > .card:not(:first-child) .card-header {
      border-top-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card:not(:first-child) .card-footer {
      border-bottom-left-radius: 0;
    }
  }
  .card-columns .card {
    margin-bottom: 0.75rem;
  }
  @media (min-width: 576px) {
    .card-columns {
      -moz-column-count: 3;
      column-count: 3;
      -moz-column-gap: 1.25rem;
      column-gap: 1.25rem;
      orphans: 1;
      widows: 1;
    }
    .card-columns .card {
      display: inline-block;
      width: 100%;
    }
  }
  .accordion {
    overflow-anchor: none;
  }
  .accordion > .card {
    overflow: hidden;
  }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px;
  }
  .fade {
    transition: opacity 0.15s linear;
  }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none;
    }
  }
  .fade:not(.show) {
    opacity: 0;
  }
  .collapse:not(.show) {
    display: none;
  }
  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none;
    }
  }
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }
  .slick-slider .slick-track, .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
  }
  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .slick-track:before, .slick-track:after {
    display: table;
    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
  .slick-loading .slick-list {
    background: #fff url("//condoms.uk/cdn/shop/t/91/assets/ajax-loader.gif?v=344831891803776881729587348") center center no-repeat;
  }
  .slick-prev, .slick-next {
    position: absolute;
    display: block;
    height: 40px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
  }
  .slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent;
  }
  .slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
    opacity: 1;
  }
  .slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
    opacity: 0.25;
  }
  .slick-prev:before, .slick-next:before {
    font-size: 20px;
    line-height: 1;
    color: #fff;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-prev {
    left: -25px;
  }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px;
  }
  .slick-prev:before {
    content: url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z'/></svg>");
  }
  [dir="rtl"] .slick-prev:before {
    content: url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z'/></svg>");
  }
  .slick-next {
    right: -25px;
  }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto;
  }
  .slick-next:before {
    content: url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z'/></svg>");
  }
  [dir="rtl"] .slick-next:before {
    content: url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z'/></svg>");
  }
  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }
  .slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
  }
  .slick-dots li button {
    border: 0;
    background: transparent;
    display: block;
    height: 20px;
    width: 20px;
    outline: none;
    line-height: 0px;
    font-size: 0px;
    color: transparent;
    padding: 5px;
    cursor: pointer;
  }
  .slick-dots li button:hover, .slick-dots li button:focus {
    outline: none;
  }
  .slick-dots li button:hover:before, .slick-dots li button:focus:before {
    opacity: 1;
  }
  .slick-dots li button:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "•";
    width: 20px;
    height: 20px;
    font-size: 6px;
    line-height: 20px;
    text-align: center;
    color: #000;
    opacity: 0.25;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-dots li.slick-active button:before {
    color: #000;
    opacity: 0.75;
  }
  .cart-container table.bt thead, .cart-container table.bt tbody th {
    display: none;
  }
  .cart-container table.bt tfoot th, .cart-container table.bt tfoot td, .cart-container table.bt tbody td {
    border: none;
    display: block;
    display: flex;
    vertical-align: top;
    float: left\9;
    width: 100% \9;
  }
  .cart-container table.bt tfoot th::before, .cart-container table.bt tfoot td::before, .cart-container table.bt tbody td::before {
    content: attr(data-th) ": ";
    display: inline-block;
    -ms-flex-shrink: 0;
    flex-shrink: 0;
    font-weight: bold;
    width: 6.5em;
  }
  .cart-container table.bt tfoot th.bt-hide, .cart-container table.bt tfoot td.bt-hide, .cart-container table.bt tbody td.bt-hide {
    display: none;
  }
  .cart-container table.bt tfoot th .bt-content, .cart-container table.bt tfoot td .bt-content, .cart-container table.bt tbody td .bt-content {
    vertical-align: top;
  }
  .bt-wrapper.active {
    max-height: 310px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .cart-container table.bt.bt--no-header tfoot td::before, .cart-container table.bt.bt--no-header tbody td::before {
    display: none;
  }
  /*! Pushy - v1.4.0 - 2020-12-28
   * Pushy is a responsive off-canvas navigation menu using CSS transforms & transitions.
   * https://github.com/christophery/pushy/
   * by Christopher Yee */
  .pushy {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 9999;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .pushy a {
    display: block;
    color: #47444b;
    padding: 10px 30px;
    text-decoration: none;
  }
  .pushy a:hover {
    color: #47444b;
  }
  .pushy ul:first-child {
    margin-top: 10px;
  }
  .pushy.pushy-left {
    left: 0;
  }
  .pushy.pushy-right {
    right: 0;
  }
  .pushy-content {
    visibility: hidden;
  }
  .pushy-left {
    transform: translate3d(-100%, 0, 0);
  }
  .pushy-open-left #container, .pushy-open-left .push {
    transform: translate3d(100%, 0, 0);
  }
  .pushy-right {
    transform: translate3d(100%, 0, 0);
  }
  .pushy-open-right #container, .pushy-open-right .push {
    transform: translate3d(-100%, 0, 0);
  }
  .pushy-open-left .pushy, .pushy-open-right .pushy {
    transform: translate3d(0, 0, 0);
  }
  .pushy-open-left .pushy-content, .pushy-open-right .pushy-content {
    visibility: visible;
  }
  #container, .pushy, .push {
    transition: transform 0.2s cubic-bezier(0.16, 0.68, 0.43, 0.99);
  }
  .pushy-content {
    transition: visibility 0.2s cubic-bezier(0.16, 0.68, 0.43, 0.99);
  }
  .site-overlay {
    display: none;
  }
  .pushy-open-left .site-overlay, .pushy-open-right .site-overlay {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9998;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-animation: fade 500ms;
    animation: fade 500ms;
  }
  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .pushy-submenu > ul {
    padding-left: 15px;
    transition: max-height 0.2s ease-in-out, visibility 0.2s ease-in-out;
  }
  .pushy-submenu > ul .pushy-link {
    transition: opacity 0.2s ease-in-out;
  }
  .pushy-submenu button {
    width: 100%;
    color: #b3b3b1;
    padding: 10px 30px;
    text-align: left;
    background: transparent;
    border: 0;
  }
  .pushy-submenu button:hover {
    color: #FFF;
  }
  .pushy-submenu > a, .pushy-submenu > button {
    position: relative;
  }
  .pushy-submenu > a::after, .pushy-submenu > button::after {
    content: '';
    display: block;
    height: 11px;
    width: 8px;
    position: absolute;
    top: 50%;
    right: 15px;
    background: url("") no-repeat;
    transform: translateY(-50%);
    transition: transform 0.2s;
  }
  .pushy-submenu-closed > ul {
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
  }
  .pushy-submenu-closed .pushy-link {
    opacity: 0;
  }
  .pushy-submenu-open > ul {
    max-height: 1000px;
    visibility: visible;
  }
  .pushy-submenu-open .pushy-link {
    opacity: 1;
  }
  .pushy-submenu-open > a::after, .pushy-submenu-open > button::after {
    transform: translateY(-50%) rotate(90deg);
  }
  .pushy-content ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .pushy-content ul li.pushy-close {
    display: flex;
    padding: 15px 30px;
    border-bottom: 1px solid #d5d5e9;
    align-items: center;
  }
  .pushy-content ul li.pushy-close svg {
    width: 15px;
    margin-right: 15px;
  }
  .pushy-content ul li.pushy-close .mobilenav-close {
    font-size: 18px;
  }
  .pushy-content ul li ul {
    padding-left: 15px;
  }
  .pushy-content ul li.pushy-submenu button {
    width: 100%;
  }
  .pushy-content ul li.pushy-submenu button .pushy-sub-controls {
    float: right;
  }
  .pushy-content ul li.pushy-submenu button .pushy-sub-open {
    display: block;
  }
  .pushy-content ul li.pushy-submenu button .pushy-sub-open svg {
    height: 18px;
  }
  .pushy-content ul li.pushy-submenu button .pushy-sub-closed {
    display: none;
  }
  .pushy-content ul li.pushy-submenu button .pushy-sub-closed svg {
    width: 18px;
  }
  .pushy-content ul li.pushy-submenu.pushy-submenu-open button .pushy-sub-open {
    display: none;
  }
  .pushy-content ul li.pushy-submenu.pushy-submenu-open button .pushy-sub-closed {
    display: block;
  }
  .pushy-content ul .pushy-submenu button, .pushy-content ul .pushy-submenu button:hover {
    color: #47444b;
    outline: none;
  }


  @media (min-width: 992px) {
    .mobile-nav-btn {
      display: none !important;
    }
    
    .mobile-nav {
      display: none !important;
    }
  }

  .mobile-nav-btn {
    margin-left: 14px;
    margin-right: 14px;
  }

  .mobile-nav {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 9999;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-direction: column;
    transform: translate3d(-100%, 0, 0); 
    transition: all 0.3s;
  }

  .mobile-nav.open {
    transform: none; 
  }

  .mobile-nav .mobile-nav-header {
    display: flex;
    padding: 13px 36px;
    border-bottom: 1px solid #E0E0E0;
  }

  .mobile-nav .mobile-nav-back {
    display: none;
    margin: auto;
  }

  .mobile-nav .mmobile-nav-close {
    transform: scale(0.9);
    margin: auto;
  }

  .mobile-nav .mobile-nav-title {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.04em;
    flex: 1;
    color: #13B4EF;
    text-transform: uppercase;
    text-align: center;
  }

  .mobile-nav .mobile-nav-content {
    flex: 1;
    padding: 0 36px;
  }

  .mobile-nav .mobile-nav-content ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  div[data-page] {
    display: none;
  }

  ul.brands-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    margin-top: 20px !important;
  }
  
  ul.brands-list a {
    border: none !important;
    padding: 12px 0 !important;
    font-weight: 600 !important;
    line-height: 16px !important;
    text-transform: initial !important;
  }

  .mobile-nav .mobile-nav-content a {
    display: flex;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #000000;
    padding: 16px 0;
    border-bottom: 1px solid #E0E0E0;
  }
  
  .mobile-nav .mobile-nav-content a.submenu::after {
    content: '';
    display: block;    
    width: 12px;
    height: 16px;
    margin: auto 0;
    margin-left: auto;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMjAgNTEyIj4KICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTI4NS40NzYgMjcyLjk3MUw5MS4xMzIgNDY3LjMxNGMtOS4zNzMgOS4zNzMtMjQuNTY5IDkuMzczLTMzLjk0MSAwbC0yMi42NjctMjIuNjY3Yy05LjM1Ny05LjM1Ny05LjM3NS0yNC41MjItLjA0LTMzLjkwMUwxODguNTA1IDI1NiAzNC40ODQgMTAxLjI1NWMtOS4zMzUtOS4zNzktOS4zMTctMjQuNTQ0LjA0LTMzLjkwMWwyMi42NjctMjIuNjY3YzkuMzczLTkuMzczIDI0LjU2OS05LjM3MyAzMy45NDEgMEwyODUuNDc1IDIzOS4wM2M5LjM3MyA5LjM3MiA5LjM3MyAyNC41NjguMDAxIDMzLjk0MXoiPjwvcGF0aD4KICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICA8L3N2Zz4=");
  }

  .mobile-nav .emoji-icon {
    font-size: 20px;
    line-height: 26px;
    margin-right: 10px;
  }

/*   .mobile-nav .mobile-nav-footer {
    padding: 0 36px;
    display: flex;
    flex-direction: column;
    margin-bottom: 96px;
  }

  .mobile-nav .mobile-nav-footer a {
    padding: 12px 0;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;    
    color: #46444A;
  } */

  
  @media (min-width: 768px) {
    .quick-menu-container {
      display: none !important;
    }
  }

  .quick-menu-container {
    position: absolute;
    top: 50px;
    display: flex;
    gap: 8px;
    width: 100vw;
    overflow-x: auto;
    padding: 0 20px;
    z-index: 999999;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .quick-menu-container::-webkit-scrollbar {
    display: none;
  }

  .quick-menu-container .quick-menu-item {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    padding: 12px 18px;
    border-radius: 60px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .quick-menu-container .quick-menu-item.durex {
    color: #fff;
    background: #005BAA;
  }

  .quick-menu-container .quick-menu-item.mysize {
    color: #fff;
    background: #011731;
  }

  .quick-menu-container .quick-menu-item.pasante {
    color: #fff;
    background: #0083CB;
  }

  .quick-menu-container .quick-menu-item.more {
    color: #000;
    background: #fff;
  }


  
  body {
    //font-family: 'Nunito', sans-serif;
    font-size: 16px;
    color: #47444b;
  }
  .boost-pfs-container-default {
    max-width: 1140px !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  a {
    color: #007bff;
    transition: 0.3s;
  }
  a:hover {
    text-decoration: none;
  }
  h1, h2, h3, h4, h5 {
    font-weight: 700;
  }
  h1 {
    font-size: 42px;
  }
  .heading-medium {
    font-size: 32px;
    line-height: 40px;
  }
  .text-small {
    font-size: 12px;
  }
  .gutter-top {
    margin-top: 30px;
  }
  .btn-default {
    background-color: #421e6e;
    padding: 2px 30px;
    border-radius: 3px;
    color: #fff;
    padding: 10px 30px;
  }
  .btn-purple {
    background-color: #9b56f1;
    color: #fff;
  }
  .img-wrap img {
    width: auto;
    height: 100%;
  }
  .blog-archive-container .img-wrap img {
    object-fit: cover;
    width: 100%;
    height: 200px;
  }
  .icon {
    display: inherit;
  }
  .icon.icon-small svg {
    height: 18px;
    width: auto;
  }
  .icon.icon-round {
    border: 1px solid #fff;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .icon.icon-round svg {
    height: 12px;
    width: auto;
  }
  .full-header {
    color: #fff;
  }
  .full-header a {
    color: #fff;
  }
  @media (max-width: 991.98px) {
    html, body {
      overflow-x: hidden;
    }
    .btn {
      padding-top: 7px;
      padding-bottom: 7px;
      font-weight: 600;
    }
  }
  .shopify-challenge__button.btn {
    background-color: #9b56f1;
  }
  #shopify-section-topbar {
    background-color: #ff0078;
    color: #fff;
    padding: 7px 0;
    text-align: center;
    width: 100%;
  }
  #shopify-section-topbar a {
    color: #fff;
  }
  #shopify-section-header-main {
    background-color: #9b56f1;
    width: 100%;
    padding: 30px 0;
  }
  #shopify-section-header-main .img-wrap {
    max-width: 330px;
    height: auto;
  }
  #shopify-section-header-main .header-search input {
    border: 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    max-height: 35px;
  }
  #shopify-section-header-main .header-search button {
    background-color: #fff;
    border: 0;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  #shopify-section-header-main .header-search button svg {
    color: #13b4ef;
    fill: #13b4ef;
  }
  #shopify-section-header-main .boost-pfs-search-form {
    max-width: 100%;
    margin-bottom: 0;
  }
  #shopify-section-header-main .boost-pfs-search-form .boost-pfs-search-box {
    border: 0;
    border-radius: 20px;
    max-height: 35px;
  }
  #shopify-section-header-main .boost-pfs-search-form .boost-pfs-search-btn:before {
    color: #9b56f1;
    font-weight: bold;
  }
  #shopify-section-header-main .header-controls {
    list-style: none;
    padding: 0;
    margin: 0 0 0 30px;
  }
  #shopify-section-header-main .header-controls li {
    display: inline-block;
    margin-left: 7px;
  }
  #shopify-section-header-main .header-controls li .icon-round {
    border-color: #fff;
  }
  #shopify-section-header-main .header-controls li .icon-round svg {
    color: #fff;
    fill: #fff;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: 16px;
    padding-right: 16px;
  }
  #shopify-section-navbar {
    background-color: #fff;
  }
  #shopify-section-navbar a {
    color: #47444b;
  }
  #beadbrumbs {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .mobile-col svg {
    fill: #fff;
    color: #fff;
  }
  .header-basket-wrap {
    position: relative;
  }
  .header-basket-wrap .basket-count {
    position: absolute;
    bottom: -7px;
    background: #fff;
    color: #9b56f1;
    font-weight: bold;
    height: 15px;
    width: 15px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    right: 0;
  }
  .menu-btn {
    background-color: transparent;
    border: none;
  }
  @media (max-width: 991.98px) {
    .header-basket-wrap .basket-count {
      bottom: -7px;
      right: -7px;
    }
    .mobile-search {
      margin-top: 30px;
/*       display: none; */
    }
    .navbar-nav .dropdown-menu {
      border: none;
      text-align: center;
    }
    #shopify-section-topbar {
      padding: 10px 0;
      font-size: 10px;
    }
    .navbar {
      padding: 0px;
      text-align: center;
    }
    .navbar-toggler {
      background-color: transparent;
      border: 0;
    }
  }
  .page {
    background-color: #f3f5f6;
  }
  .page .page-top {
    background-color: #95e;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .page .page-top h1 {
    margin: 0 0 15px 0;
    color: #fff;
    line-height: 42px;
  }
  .page .page-top #breadcrumbs .breadcrumb a, .page .page-top #breadcrumbs .breadcrumb span {
    color: #fff !important;
  }
  .page h1 {
    margin-top: 30px;
  }
  .page aside {
    background-color: #fff;
    padding: 30px;
  }
  .page .page-main {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .contact-form .btn {
    background-color: #9b56f1;
    border: 0;
    width: 50%;
  }
  .faqs {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .thankyou {
    background-color: #ff0078;
    width: 100%;
    padding: 30px;
    font-size: 18px;
    color: #fff;
    margin-bottom: 30px;
  }
  @media (max-width: 991.98px) {
    table.bt tfoot th::before, table.bt tfoot td::before, table.bt tbody td::before {
      background-color: #9b56f1;
      color: #fff;
      padding: 5px;
    }
    table.delivery th, table.delivery td {
      padding: 0 !important;
      margin-top: 5px !important;
      background-color: #fff;
    }
    table.bt tfoot th .bt-content, table.bt tfoot td .bt-content, table.bt tbody td .bt-content {
      padding-left: 7px;
    }
    .contact-wrap {
      text-align: center;
    }
    .contact-wrap aside {
      margin-bottom: 30px;
    }
    .contact-wrap .btn {
      width: 100%;
    }
    .page-terms .page-side {
      margin-bottom: 30px;
    }
  }
  #contact_form .btn {
    text-transform: capitalize;
  }
  table.delivery {
    border-collapse: collapse;
    border-color: #eeeeee;
    border-style: solid;
    border-width: 1px;
    table-layout: fixed;
  }
  table.delivery th {
    background-color: #9b56f1;
    color: #fff;
  }
  table.delivery th, table.delivery td {
    border-color: #eeeeee;
    border-style: solid;
    border-width: 1px;
    padding: 10px;
    text-align: left;
  }
  .home-section-heading {
    width: 100%;
    text-align: center;
    color: #9b56f1;
    margin-bottom: 15px;
  }



  #shopify-section-quiz {
    background: black;
    padding: 3px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px 0px 20px;
    color: white;
    font-size: 18px;
  }
  #shopify-section-quiz .banana {
    display: flex;
    font-size: 40px;
    margin-right: 20px;
  }
  #shopify-section-quiz a {
    font-size: 16px;
    margin-left: 20px;
    background: #FC0;
    padding: 8px 30px;
    color: white;
    font-weight: bold;
    border-radius: 4px;
  }
  @media (max-width: 991.98px) {
      #shopify-section-quiz {
          padding: 20px 0px;
          text-align: center;
          flex-direction: column;
      } 
      #shopify-section-quiz .banana {
          display: none !important;
      } 
      #shopify-section-quiz a {
          margin-left: 0;
          margin-top: 16px;
          width: 240px;
          padding-top: 12px; 
          padding-bottom: 12px; 
      }
  }

  
  #shopify-section-home-hero {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 18px 0;
    color: #fff;
    background-color: #34B3D8;
  }
  #shopify-section-home-hero .hero-left {
    text-align: right;
  }
  #shopify-section-home-hero .hero-left img {
    width: 50%;
    margin-right: 30px;
  }
  #shopify-section-home-hero h1 {
    color: #fff;
    margin: 0;
    padding: 0;
    line-height: 42px;
  }
  #shopify-section-home-hero .hero-txt {
    margin: 15px 0;
  }
  #shopify-section-home-top-boxes .home-box {
    background-size: 40%;
    background-position: bottom right;
    background-repeat: no-repeat;
    padding: 15px;
  }
  #shopify-section-home-top-boxes .home-box .home-box-content {
    color: #fff;
  }
  #shopify-section-home-top-boxes .home-box .home-box-content .heading-medium {
    font-size: 26px;
    line-height: 26px;
    margin: 0;
  }
  #shopify-section-home-top-boxes .home-box .home-box-content p {
    margin: 7px 0;
    line-height: 20px;
    font-size: 14px;
    width: 60%;
  }
  #shopify-section-home-popular .col {
    max-width: calc(20% - 30px);
  }
  #shopify-section-home-popular .popular-brand {
    text-align: center;
  }
  #shopify-section-home-popular .popular-brand a {
    color: #47444b;
  }
  #shopify-section-home-popular .popular-brand a:hover {
    text-decoration: none;
  }
  #shopify-section-home-popular .popular-brand span {
    width: 100%;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #shopify-section-home-popular .popular-brand img {
    max-width: calc(100% - 30px);
  }
  #shopify-section-home-featured-collection {
    margin-top: 30px;
    padding-top: 30px;
    margin-bottom: 30px;
    background-color: #f3f5f6;
  }
  #shopify-section-home-featured-collection h2 {
    margin: 0 0 30px 0;
  }
  #shopify-section-home-featured-collection .product-grid-item {
    background-color: #fff;
    margin-bottom: 30px;
    padding: 15px;
    height: calc(100% - 30px);
    display: flex;
    flex-direction: column;
  }
  #shopify-section-home-featured-collection .product-grid-item .grid-content {
    margin-top: auto;
  }
  #shopify-section-home-featured-collection .product-grid-item .grid-content .product-grid-heading {
    margin-bottom: 7px;
  }
  #shopify-section-home-featured-collection .product-grid-item .grid-content .product-grid-heading a {
    font-size: 16px;
    color: #47444b;
  }
  #shopify-section-home-featured-collection .product-grid-item .grid-content .collection-item-price {
    margin: 0 0 7px;
    color: #9b56f1;
    font-size: 21px;
    font-weight: 600;
  }
  #shopify-section-home-featured-collection .product-grid-item .grid-content .btn {
    margin-top: 7px;
    width: 100%;
  }
  @media (max-width: 991.98px) {
    #shopify-section-home-featured-collection .col {
      width: 50%;
      max-width: 50%;
      flex-basis: 50%;
    }
    #shopify-section-home-featured-collection h2 {
      text-align: center;
    }
  }
  #shopify-section-home-fantastic {
    padding: 30px 0;
    text-align: center;
  }
  @media (max-width: 991.98px) {
    #shopify-section-home-hero {
      text-align: center;
    }
    #shopify-section-home-hero .hero-left img {
      width: 70%;
      margin-right: 15%;
      margin-bottom: 16px;
    }
    #shopify-section-home-hero .hero-txt {
      margin: 15px auto;
    }
    #shopify-section-home-hero .hero-txt, #shopify-section-home-hero .btn {
      width: 70%;
    }
    .home-box {
      margin-top: 16px;
    }
    .home-box.home-box-1 {
      margin-top: 0;
    }
    #shopify-section-home-popular .col {
      max-width: 50%;
      flex-basis: 50%;
    }
    #shopify-section-home-featured-collection .product-grid-item {
      text-align: center;
    }
  }
  .page-terms .terms-content-block {
    margin-bottom: 30px;
  }
  .page-terms .sidebar-row ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .page-terms .sidebar-row ul li {
    display: block;
    border-bottom: 1px solid #dee2e6;
    width: 100%;
  }
  .page-terms .sidebar-row ul li a {
    padding: 7px 0;
    display: inline-block;
  }
  .blog {
    background-color: #f3f5f6;
  }
  #shopify-section-sidebar-blog {
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
  }
  #shopify-section-sidebar-blog h2 {
    color: #9b56f1;
    font-size: 26px;
  }
  #shopify-section-sidebar-blog ul {
    list-style: none;
    padding: 0;
    margin: 15px 0 0 0;
  }
  #shopify-section-sidebar-blog ul li {
    display: block;
    width: 100%;
  }
  #shopify-section-sidebar-blog ul li a {
    color: #47444b;
    margin-bottom: 7px;
    display: block;
    font-weight: 600;
  }
  #shopify-section-sidebar-blog ul li a:hover {
    color: #9b56f1;
  }
  .blog-header {
    padding-bottom: 30px;
    padding-top: 30px;
    margin-bottom: 30px;
    background-color: #FD9301;
    color: #fff;
  }
  .blog-archive-container.related-blogs {
    margin-bottom: 30px;
    margin-left: -15px;
    margin-right: -15px;
  }
  .blog-archive-container .card {
    background-color: #fff;
    margin-bottom: 30px;
    height: 100%;
  }
  .blog-archive-container .card .article-meta {
    font-size: 12px;
  }
  .blog-archive-container .card .card-title a {
    font-size: 22px;
    color: #47444b !important;
    text-decoration: none !important;
    line-height: 26px;
  }
  .blog-archive-container .card time, .blog-archive-container .card .author {
    color: rgb(0, 123, 255);
    font-weight: 600;
  }
  .blog-archive-container .card .card-body {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .blog-archive-container .card .card-footer {
    padding: 0 15px 15px;
    background-color: transparent;
    background: transparent;
    border: 0;
  }
  .blog-archive-container .card .btn {
    background-color: #fd9301;
    width: 100%;
    min-width: 100%;
    margin-top: 0;
    text-transform: uppercase;
    font-weight: normal;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .blog-archive-container .blog-1-img {
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
  }
  .blog-archive-container .img-wrap {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .archive-col {
    margin-bottom: 30px;
  }
  .blog-card-mb {
    margin-bottom: -30px;
  }
  .blog-card-mb .btn {
    margin-top: 15px !important;
    margin-bottom: 15px;
  }
  .blog-card-mb .blog-1-img {
    background-size: cover !important;
  }
  .btn-btb {
    background-color: #9b56f1;
  }
  .blog-slide-item {
    margin-right: 15px;
    margin-left: 15px;
  }
  .slick-track {
    display: flex !important;
  }
  .slick-slide {
    height: inherit !important;
  }
  @media (max-width: 991.98px) {
    .blog-header {
      text-align: center;
    }
    #shopify-section-sidebar-blog {
      margin-bottom: 15px;
      text-align: center;
    }
    .btn-btb {
      margin-bottom: 15px;
      width: 100%;
    }
    .blog-slide-item {
      margin: 0 45px 15px 45px;
    }
    .slick-prev {
      left: 15px;
    }
    .slick-next {
      right: 15px;
    }
  }
  .article main {
    background-color: #f3f5f6;
  }
  .article main .article-container .blog-main {
    background-color: #fff;
    margin-bottom: 20px;
  }
  .article main .article-container .blog-main .article-img .img-wrap {
    max-height: 350px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .article main .article-container .blog-main .article-img .img-wrap img {
    width: 100%;
  }
  .article main .article-container .blog-main .article-content {
    padding: 15px;
  }
  .article main .article-container .blog-main .article-content h2 {
    font-size: 32px;
  }
  .article main .article-container .blog-main .article-content h3 {
    font-size: 26px;
  }
  .article main .article-container .blog-main .article-content time, .article main .article-container .blog-main .article-content .author {
    color: rgb(0, 123, 255);
    font-weight: 600;
  }
  .article main .related-blogs h4 {
    font-size: 32px;
    color: #000;
    margin: 30px 0;
    padding-left: 15px;
  }
  .product {
    background-color: #f3f5f6;
  }
  .product #AddToCart {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
  .product .product__title {
    margin-top: 15px;
    font-weight: 600;
    margin-bottom: 7px;
  }
  .product .product__price {
    margin-bottom: 7px;
    color: #9b56f1;
    font-size: 21px;
    font-weight: 600;
  }
  .product .stamped-product-reviews-badge {
    margin-bottom: 7px;
  }
  .cust-variant-selector select {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  #product-carousel .carousel-item {
    background-color: #fff;
  }
  #product-carousel .carousel-inner, #product-carousel .img-wrap {
    height: 450px;
    text-align: center;
    background-color: #fff;
  }
  #product-carousel .carousel-inner img, #product-carousel .img-wrap img {
    max-height: 450px !important;
    width: auto;
    margin: auto;
  }
  #product-carousel .carousel-nav {
    width: 100%;
    margin-top: 15px;
    display: none !important;
  }
  #product-carousel .carousel-nav .list-inline-item {
    cursor: pointer;
    padding: 5px;
    background-color: #fff;
  }
  #product-carousel .carousel-nav .list-inline-item img {
    width: 100%;
    height: auto;
  }
  #accordion {
    width: 100%;
    margin-top: 30px;
  }
  #accordion .card {
    border: none;
    border-radius: 0;
    background-color: transparent;
    border-top: 1px solid #000000;
  }
  #accordion .card .card-header {
    padding: 0;
    background-color: transparent !important;
    border: none;
  }
  #accordion .card .card-header h2 {
    border: none !important;
    background-color: transparent !important;
    display: block;
    width: 100%;
    text-align: left;
    position: relative !important;
    padding: 15px 0;
    color: #15b3ee !important;
    font-size: 26px;
  }
  #accordion .card .card-header h2:focus, #accordion .card .card-header h2:hover {
    box-shadow: none !important;
    text-decoration: none !important;
  }
  #accordion .card .card-header h2 .item-state {
    position: absolute;
    right: 15px;
    top: 15px;
  }
  #accordion .card .card-header h2 .item-state svg {
    height: 25px;
    width: auto;
    fill: #15b3ee;
    color: #15b3ee;
  }
  #accordion .card .card-header.accordion-open h2 .item-is-open {
    display: block;
  }
  #accordion .card .card-header.accordion-open h2 .item-is-closed {
    display: none;
  }
  #accordion .card .card-header.accordion-closed h2 .item-is-open {
    display: none;
  }
  #accordion .card .card-header.accordion-closed h2 .item-is-closed {
    display: block;
  }
  #accordion .card .card-body {
    padding: 0 0 15px;
  }

  .product-container .price-field {
    color: #9b56f1;
    font-weight: 700;
    font-size: 26px;
  }
  .product-container .btn {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    background-color: #7bdd3b;
    height: 100%;
    text-transform: uppercase;
    font-weight: 700;
  }
  .tick-list {
    list-style: none;
    padding: 0;
    margin: 15px 0;
  }
  .tick-list li {
    display: block;
    margin-bottom: 7px;
  }
  .list-img {
    margin-right: 7px;
  }
  .list-img img {
    width: 25px;
    height: auto;
    border-radius: 3px;
  }
  .product-reviews-wrap {
    width: 100%;
  }
  .product-reviews-wrap #stamped-main-widget {
    width: 100%;
  }
  .boost-pfs-filter-product-item-image {
    padding: 15px;
  }
  @media (max-width: 991.98px) {
    #accordion .card .card-header h2 {
      padding-right: 30px;
      font-size: 22px;
    }
    .ajax-added-modal .ajax-added-modal-inner {
      width: calc(100% - 20px) !important;
      background-color: #fff;
      z-index: 999999999;
      padding: 16px !important;
      position: fixed;
      top: -100%;
      text-align: center;
      transition: 1s all;
      border: 2px solid #47444b;
      left: 10px !important;
    }
    .ajax-added-modal .ajax-added-modal-inner h4 {
      line-height: 120% !important;
    }

    .ajax-added-modal .ajax-added-modal-inner .ajax-rec-add-to-cart {
      font-size: 14px !important;
    }

    #product-carousel .carousel-inner, #product-carousel .img-wrap {
      height: 300px;
      text-align: center;
      background-color: #fff;
      display: flex;
      max-width: 100%;
    }
    #product-carousel .carousel-inner img, #product-carousel .img-wrap img {
      max-height: 290px !important;
      width: auto;
      margin: auto;
    }
    .collection .boost-pfs-filter-product-item .boost-pfs-filter-product-item-inner {
      display: flex;
    }
    .collection .boost-pfs-filter-product-item .boost-pfs-filter-product-item-inner .boost-pfs-filter-product-item-image-link {
      padding-top: 100% !important;
    }
    .collection .boost-pfs-filter-product-item .boost-pfs-filter-product-item-inner .boost-pfs-filter-product-item-image {
      width: 50%;
    }
    .collection .boost-pfs-filter-product-item .boost-pfs-filter-product-item-inner .boost-pfs-filter-product-bottom {
      width: 50%;
      margin-top: 15px;
    }
    .collection .boost-pfs-filter-product-item .boost-pfs-filter-product-item-inner .boost-pfs-filter-product-bottom p {
      text-align: left;
      font-size: 16px;
    }
    .collection .boost-pfs-filter-product-item .boost-pfs-filter-product-item-inner .boost-pfs-filter-product-bottom a {
      text-align: left;
      font-size: 16px;
    }
    #AddToCart {
      margin-top: 15px;
    }
    .boost-pfs-filter-toolbar-top-mobile {
      padding-left: 15px;
      padding-right: 15px;
      border: 0;
    }
    .boost-pfs-filter-toolbar-top-mobile .boost-pfs-filter-top-sorting button, .boost-pfs-filter-toolbar-top-mobile .boost-pfs-filter-tree-mobile-button button {
      background-color: #47444b;
      color: #fff;
      text-align: center;
    }
    .boost-pfs-filter-toolbar-top-mobile .boost-pfs-filter-tree-mobile-button button:before {
      display: none;
    }
    .boost-pfs-filter-top-display-type {
      display: none;
    }
    .boost-pfs-filter-product-item.boost-pfs-filter-grid-width-mb-2 {
      width: 100% !important;
    }
    .collection .boost-pfs-action-list-enabled .boost-pfs-addtocart-btn {
      width: 90% !important;
      padding-top: 7px !important;
      padding-bottom: 7px !important;
      color: #fff !important;
      border: none !important;
      background-color: #9b56f1 !important;
      background: #9b56f1 !important;
      border-radius: 3px !important;
      margin-bottom: 15px !important;
    }
    .collection .boost-pfs-filter-product-item .boost-pfs-filter-product-item-inner .boost-pfs-filter-product-bottom {
      text-align: center !important;
    }
    .collection .boost-pfs-filter-product-item-image-link img.lazyloaded {
      margin: 0 auto 15px;
    }
    .product-container h1, .product-container .stamped-product-reviews-badge, .product-container .price-field {
      text-align: center;
    }
    .product-container h1 {
      margin-top: 30px;
      font-size: 24px;
      text-align: left;
    }
    .product-container .stamped-product-reviews-badge.stamped-main-badge, .product-container .price-field {
      text-align: left;
    }
    .product-container .product-txt {
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 30px;
      margin-bottom: 15px;
    }
    #shopify-section-product-recommendations .heading-medium {
      padding-right: 15px;
      padding-left: 15px;
      font-size: 24px;
    }
    #shopify-section-product-recommendations .product-slide {
      margin: 15px 45px;
    }
    #shopify-section-product-recommendations .slick-prev {
      left: 15px;
      z-index: 100;
    }
    #shopify-section-product-recommendations .slick-next {
      right: 15px;
    }
  }
  #shopify-section-tabbed-description-section {
    border-bottom: 1px solid #000000;
    margin-bottom: 30px;
  }
  .product-container .btn.atcwide {
    height: unset;
    margin-bottom: 16px;
  }
  .quantity-field, .single-option-selector {
    min-height: 45px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-position: right center;
    background-image: url("//condoms.uk/cdn/shop/t/91/assets/ico-select.svg?v=16222237842968735801729587348");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-color: transparent;
    padding-right: 28px;
    text-indent: 0.01px;
    text-overflow: '';
    cursor: pointer;
    border: 1px solid #000 !important;
    background-color: #fff;
  }
  .boost-pfs-filter-product-item-vendor {
    display: none;
  }
  .boost-pfs-filter-total-product {
    display: none !important;
  }
  .ajax-added-modal .ajax-added-modal-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999999999;
  }
  .ajax-added-modal .ajax-added-modal-inner {
    width: 560px;
    background-color: #fff;
    z-index: 999999999;
    padding: 15px 30px;
    position: fixed;
    top: -100%;
    left: calc(50% - 225px);
    text-align: center;
    transition: 1s all;
    border: 2px solid #47444b;
  }
  .ajax-added-modal .ajax-added-modal-inner.modalinnerdown {
    top: 5%;
  }
  .ajax-added-modal .ajax-added-modal-inner .ajax-added-modal-x {
    position: absolute;
    top: 14px;
    right: 19px;
    line-height: 100%;
    font-size: 20px;
    color: #7A7580;
    cursor: pointer;
  }
  .ajax-added-modal .ajax-added-modal-inner h3 {
    margin-top: 7px;
    margin-bottom: 20px;
    color: #3B9C49;
    font-size: 18px;
    line-height: 25px;
  }
  .ajax-added-modal .ajax-added-modal-inner h3 svg {
    width: 22px;
    height: 22px;
    margin-top: -3px;
  }
  .ajax-added-modal .ajax-added-modal-inner img {
    max-width: 100px;
    border-radius: 5px;
    padding: 2px;
    border: 1px solid #d9d9d9;
    margin-right: 20px;
    align-self: flex-start;
  }

  .ajax-added-modal .ajax-added-modal-inner h5 {
    font-family: 'Nunito' !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #6D6C6F;
    margin-bottom: 4px;
  }

  .ajax-added-modal .ajax-added-modal-inner h5.price {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700 !important;
    font-size: 16px;
    line-height: 100%;
    color: #47444B;
  }

  .ajax-added-modal .ajax-added-modal-inner .ajax-rec-add-to-cart {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;    
    color: #3B9C49;
    border: 2px solid #3B9C49;
    border-radius: 3px;
    text-transform: uppercase;
    padding: 8px 22px;
    text-align: center;
    line-height: 100%;
    background: transparent;
    margin-top: 6px;
  }
  @media (min-width: 991.98px) {
      .ajax-added-modal .ajax-added-modal-inner .ajax-rec-add-to-cart {
        padding: 8px 44px !important;
      }
  }
  
  .ajax-added-modal .ajax-added-modal-inner .ajax-added-modal-btn {
    width: 100%;
    display: block;
    border: 1px solid #47444b;
    border-radius: 3px;
    padding: 7px 0;
    margin-top: 20px;
    margin-bottom: 5px;
    font-weight: bold;
    position: relative;
  }
  .ajax-added-modal .ajax-added-modal-inner .ajax-added-modal-btn.ajax-added-modal-goto-cart {
    color: #fff;
    background-color: #3b9c49;
    text-transform: uppercase;
    font-weight: bold;
    border: none;
  }
  .ajax-added-modal .ajax-added-modal-inner .ajax-added-modal-btn.ajax-added-modal-cont {
    color: #000 !important;
    background: transparent  !important;
    text-transform: uppercase;
    font-weight: bold;
    border: none;
    cursor: pointer !important;
    margin-top: 8px;
    margin-bottom: 0;
  }
  .collection-content {
    padding-bottom: 60px;
  }
  .carousel-nav > .row {
    overflow-x: auto;
    white-space: nowrap;
    display: block;
  }
  .carousel-nav > .row > .col-4 {
    display: inline-block;
  }
  .thumb-menu-wrapper {
    position: relative;
    max-width: 100%;
    height: 100px;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .thumb-menu {
    height: 120px;
    background: #f3f3f3;
    box-sizing: border-box;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    padding: 0;
  }
  .thumb-menu .thumb-item {
    display: inline-block;
    width: 100px;
    height: 100%;
    box-sizing: border-box;
  }
  .paddles .paddle {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 3em;
    background-color: #fff;
    opacity: 0.5;
    border: none;
  }
  .paddles .paddle:hover {
    opacity: 1;
  }
  .paddles .paddle svg {
    height: 30px;
    color: #9b56f1;
    fill: #9b56f1;
  }
  .paddles .left-paddle {
    left: 0;
  }
  .paddles .right-paddle {
    right: 0;
  }
  .paddles .hidden {
    display: none;
  }
  .product-breadcrumbs {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .collection main {
    background-color: #f3f5f6;
  }
  .collection .boost-custom-sb {
    background-color: #fff;
    margin-bottom: 30px;
  }
  .collection .boost-custom-sb .boost-custom-sb-content {
    padding: 15px;
  }
  .collection .boost-pfs-filter-tree-v .boost-pfs-filter-option {
    margin-bottom: 0px;
    background-color: #fff;
    padding: 20px 0px !important;
  }
  .collection .boost-pfs-filter-product-item .boost-pfs-filter-product-item-inner {
    background-color: #fff;
  }
  .collection .boost-pfs-filter-product-item .boost-pfs-filter-product-item-inner .boost-pfs-filter-product-bottom {
    padding: 0 15px 15px 15px;
  }
  .shopify-section .boost-pfs-filter-collection-header-wrapper {
    background-size: auto 200px;
    background-position: top;
    background-color: #fff;
    margin-bottom: 30px;
    text-align: left;
  }
  .shopify-section .boost-pfs-filter-collection-header-wrapper.boost-pfs-filter-collection-has-image {
    padding-top: 230px;
  }
  .shopify-section .boost-pfs-filter-collection-header-wrapper h1 {
    color: #000000;
    text-align: left;
  }
  .shopify-section .boost-pfs-filter-collection-header-wrapper.boost-pfs-filter-collection-has-image .boost-pfs-filter-collection-description {
    color: #47444b;
    text-align: left;
  }
  .collection .blog-header {
    padding-bottom: 30px;
    padding-top: 30px;
    margin-bottom: 30px;
    background-color: transparent;
    color: #47444b;
  }
  .collection .blog-header a {
    color: #47444b !important;
  }
  .collection-add {
    background-color: #9b56f1;
    color: #fff;
    width: 100%;
    border: none;
  }
  .collection-crumbs {
    position: relative;
    padding-bottom: 15px;
    border-bottom: 1px solid #d5d5e9;
  }
  .collection-crumbs .moved-toolbar {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
  }
  .collection-crumbs .moved-toolbar button, .collection-crumbs .moved-toolbar span {
    line-height: unset !important;
  }
  @media (max-width: 991.98px) {
    .boost-pfs-filter-product-item-title {
      text-align: left;
    }
    .boost-pfs-filter-toolbar-top-mobile {
      margin-bottom: 30px;
    }
    h1.boost-pfs-filter-collection-header {
      font-size: 30px;
    }
    .collection .btn {
      font-size: 14px;
      padding: 7px;
    }
    .collection .blog-header {
      margin-bottom: 0;
    }
    .collection .blog-header #breadcrumbs {
      display: none;
    }
    .moved-toolbar {
      display: none;
    }
  }
  .account-container {
    margin-bottom: 45px;
  }
  .account-container .btn {
    color: #fff !important;
  }
  .account-header {
    text-align: center;
  }
  #acctabs {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0 0 30px 0;
    border-bottom: 2px solid #47444b;
  }
  #acctabs .acc-li {
    display: inline-block;
    margin-bottom: -2px;
  }
  #acctabs .acc-li .nav-link {
    border: 0;
    border-bottom: 2px solid #47444b;
    color: #0000EE;
  }
  #acctabs .acc-li .nav-link.active {
    border-color: #47444b;
    color: #47444b;
  }
  .edit-address, .address-delete-form button {
    background-color: #3B9C49 !important;
    color: #fff !important;
  }
  .edit-address:hover, .address-delete-form button:hover {
    background-color: #0000EE !important;
    color: #fff !important;
  }
  .register-login-wrap {
    max-width: 450px;
    margin-top: 45px;
    margin-bottom: 45px;
  }
  .register-login-wrap .register-login-tabs {
    list-style: none;
    padding: 0;
    margin: 0 0 30px 0;
    display: flex;
  }
  .register-login-wrap .register-login-tabs li {
    display: inline-block;
    width: 50%;
    padding: 15px 0;
    text-align: center;
    border-bottom: 2px solid transparent;
  }
  .register-login-wrap .register-login-tabs li.tab-current {
    border-color: #47444b;
  }
  .register-login-wrap .register-login-tabs li a {
    color: #0000EE;
  }
  .register-login-wrap .btn-primary {
    width: 100%;
    border-radius: 0.25rem !important;
    background-color: #9b56f1;
    border: 0 !important;
  }
  .reset-group {
    display: none;
  }
  .shopify-challenge__button {
    width: 300px;
    color: #fff !important;
    margin-bottom: 45px !important;
    display: block !important;
    background-color: #3B9C49 !important;
  }
  .add-new-address {
    background-color: #3B9C49 !important;
  }
  .form-control:focus {
    background-color: #fff;
    border-color: #ccc;
    outline: 0;
    box-shadow: 0 0 0 0.2rem #ccc;
  }
  .myaccount__account-details {
    margin-top: 30px;
  }
  .goback {
    display: flex;
    align-items: center;
  }
  .goback svg {
    height: 26px;
    width: auto;
    margin-right: 7px;
    color: #9b56f1;
    fill: #9b56f1;
  }
  .goback svg:hover {
    color: #9b56f1;
  }
  .myaccount__order-history a {
    color: #9b56f1;
  }
  .myaccount__order-history a:hover {
    color: #9b56f1;
  }
  .single-order table, .all-orders table {
    border: 1px solid #f3f5f6;
  }
  .single-order table thead th, .single-order table thead td, .all-orders table thead th, .all-orders table thead td {
    border-right: 1px solid #fff !important;
  }
  .single-order table tr, .all-orders table tr {
    border-bottom: 1px solid #f3f5f6;
    padding-bottom: 7px;
    padding-top: 7px;
  }
  .single-order table tr th, .single-order table tr td, .all-orders table tr th, .all-orders table tr td {
    padding: 7px 15px;
    border-right: 1px solid #f3f5f6;
  }
  .single-order table tr [data-label="Total"], .all-orders table tr [data-label="Total"] {
    border-right: none !important;
  }
  #address_form_new {
    margin-top: 30px;
  }
  #address_form_new .btn {
    width: 100%;
    text-align: center;
  }
  .user-address {
    border-top: 2px dotted #f3f5f6;
    padding-top: 30px;
    margin-top: 30px;
  }
  .user-address h4 {
    margin-top: 0;
  }
  .user-address form {
    margin-top: 30px;
  }
  .user-address form.address-delete-form {
    margin-top: 0;
  }
  .user-address form.address-delete-form button {
    background: transparent;
    background-color: transparent;
    padding: 7px 15px;
    border: 1px solid #f3f5f6;
    border-radius: 3px;
    transition: all 0.3s;
  }
  .user-address form.address-delete-form button:hover {
    color: #fff;
  }
  .user-address ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .user-address ul li {
    display: inline-block;
    margin-right: 15px;
  }
  .user-address ul li a {
    padding: 7px 15px;
    border: 1px solid #f3f5f6;
    border-radius: 3px;
    transition: all 0.3s;
  }
  .user-address ul li a:hover {
    color: #fff;
  }
  .cart {
    background-color: #f3f5f6;
  }
  .cart-container {
    margin-top: 20px;
  }
  .cart-container .cart-main-wrap {
    background-color: #fff;
    border: 1px solid #d5d5e9;
    border-radius: 5px;
  }
  .cart-container table {
    width: 100%;
  }
  .cart-container table thead {
    border-bottom: 1px solid #d5d5e9;
    color: #9b56f1;
    font-weight: 600;
  }
  .cart-container table thead th, .cart-container table thead td {
    padding: 15px;
  }
  .cart-container table tbody {
    padding-top: 15px;
  }
  .cart-container table tbody tr td {
    padding: 15px;
    color: #9b56f1;
    font-weight: 600;
  }
  .cart-container table tbody tr td.rem-item {
    padding: 0;
  }
  .cart-container table tbody tr td.cart-title, .cart-container table tbody tr td.cart-title a {
    color: #47444b;
    font-size: 18px;
    font-weight: normal;
  }
  .cart-container table tbody tr td.cart-title a span {
    font-style: italic;
  }
  .cart-container table tbody tr td .quantity-field {
    max-width: 60px;
    text-align: center;
  }
  .cart-container table tbody tr td a.cart-remove {
    color: #421e6e;
  }
  .cart-container table tbody tr td a.cart-remove svg {
    color: #9b56f1;
    fill: #9b56f1;
    max-width: 24px;
    height: auto;
  }
  .cart-container .cart-sub-total {
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    text-align: right;
  }
  .cart-container .cart-sub-total span {
    font-size: 26px;
    color: #9b56f1;
    margin-left: 7px;
  }
  .cart-container .cart-footer {
    border-top: 1px solid #d5d5e9;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .cart-container .cart-footer p {
    margin: 0;
  }
  .cart-container .cart-footer .cft-update {
    text-align: center;
  }
  .cart-container .cart-footer .cft-update .btn {
    background-color: #9b56f1;
  }
  .cart-container .cart-footer .cft-update .btn:hover {
    color: #fff;
  }
  .cart-container .cart-totals-wrap {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
  }
  .cart-container .cart-totals-wrap .cart-totals {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .cart-container .cart-totals-wrap .cart-totals p {
    font-weight: 600;
    text-align: center;
    margin-bottom: 15px;
  }
  .cart-container .cart-totals-wrap .cart-totals .btn {
    color: #fff !important;
    width: 350px;
    background-color: #9b56f1;
    padding-top: 7px;
    padding-bottom: 7px;
    text-transform: uppercase;
    font-weight: 600;
  }
  #shopify-section-cart-footer {
    margin-bottom: 30px;
  }
  #shopify-section-cart-footer .cf-cards {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  #shopify-section-cart-footer .cf-cards img {
    max-width: 350px;
  }
  #shopify-section-cart-footer .cf-block {
    background-color: #fff;
    border: 1px solid #d5d5e9;
    border-radius: 2px;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #shopify-section-cart-footer .cf-block .list-img {
    margin-right: 7px;
    display: inline-flex;
  }
  .btn-chk {
    background: url("//condoms.uk/cdn/shop/t/91/assets/white-lock.png?v=105042008475255440471729587348") #3b9c49 no-repeat;
    background-position: 20px 24px;
    border-radius: 4px;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-weight: bold;
    color: #fff;
    padding: 20px 40px 20px 60px;
    border: none;
    border-radius: 5px;
  }
  .cart-mobile-wrap .mobile-cart-row {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px dashed #d5d5e9;
  }
  .cart-mobile-wrap .mobile-cart-row img {
    max-width: 100%;
  }
  .cart-mobile-wrap .mobile-cart-row .mobile-cart-title {
    color: #47444b;
    //font-weight: bold;
  }
  .cart-mobile-wrap .mobile-cart-row .mobile-cart-remove {
    display: block;
    margin: 7px 0;
  }
  .cart-mobile-wrap .mobile-cart-row .quantity-field {
    min-height: 25px;
    color: #47444b;
  }
  .cart-mobile-wrap .mobile-cart-row .mobile-cart-price {
    text-align: right;
  }
  .list-img img {
    max-height: 22px;
  }
  .boost-pfs-filter-product-bottom .btn {
    width: 100%;
  }
  @media (max-width: 991.98px) {
    #shopify-section-cart-footer .cf-block {
      margin-bottom: 15px;
      text-align: left;
      justify-content: left;
      padding-left: 15px;
    }
    .cart-container table tbody tr td a.cart-remove svg {
      width: 24px;
      max-width: 24px;
    }
    .rem-item:before {
      color: #fff;
    }
    .cart-footer {
      text-align: center;
    }
    .cart-footer .btn {
      margin-top: 15px;
      margin-bottom: 15px;
    }
    .td1:before {
      display: none !important;
    }
    .td1 .bt-content {
      text-align: center !important;
      width: 100% !important;
    }
    .td1 .bt-content img {
      height: 150px;
      width: auto;
    }
    .cart-container table tbody tr {
      border-bottom: 1px solid #d5d5e9;
    }
  }
  #shopify-section-signup-section {
    background-color: #ff0078;
    width: 100%;
    //padding-top: 15px;
  }
  #shopify-section-signup-section .suf-img {
    display: flex;
    justify-content: right;
    height: 100%;
    align-items: flex-end;
  }
  #shopify-section-signup-section .suf-img img {
    width: 250px;
  }
  #shopify-section-signup-section .suf-content {
    color: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding-top: 15px;
  }
  #shopify-section-signup-section .suf-content .text-small {
    margin-top: 15px;
  }
  #shopify-section-signup-section .suf-content .text-small a {
    color: #fff;
  }
  #shopify-section-footer {
    width: 100%;
    background-color: #9b56f1;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  #shopify-section-footer .footer-left {
    text-align: left;
    color: #fff;
    padding-right: 60px;
  }
  #shopify-section-footer .footer-left p {
    margin-top: 15px;
  }
  #shopify-section-footer .footer-left img {
    max-width: 200px;
    width: 100%;
    height: auto;
  }
  #shopify-section-footer .footer-social {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  #shopify-section-footer .footer-social li {
    display: inline-block;
    margin-right: 7px;
  }
  #shopify-section-footer .footer-social li a {
    background-color: #fff;
    height: 35px;
    width: 35px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  #shopify-section-footer .footer-social li a svg {
    height: 25px;
    width: auto;
  }
  #shopify-section-footer nav h4 {
    color: #fff;
    font-size: 18px;
  }
  #shopify-section-footer nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  #shopify-section-footer nav ul li {
    display: block;
  }
  #shopify-section-footer nav ul li a {
    color: #fff;
  }
  #shopify-section-bottom-bar {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #503670;
    text-align: center;
    color: #fff;
  }
  #shopify-section-bottom-bar .bb-cards {
    max-width: 505px;
    width: 100%;
    margin: auto;
  }
  #shopify-section-bottom-bar p {
    margin-top: 7px;
    margin-bottom: 0;
    font-size: 12px;
  }
  @media (max-width: 991.98px) {
    #shopify-section-signup-section {
      padding-top: 30px;
      //padding-bottom: 15px;
      text-align: center;
    }
    #shopify-section-signup-section .suf-content {
      text-align: center;
    }
    #shopify-section-footer {
      text-align: center;
    }
    #shopify-section-footer .footer-left {
      text-align: center;
      padding-right: 0;
      padding-left: 0;
    }
  }
  .bb-cards ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .bb-cards ul li {
    display: inline-block;
    margin: 0 2px;
  }
  .bb-cards ul li .footer-card-wrap {
    width: 40px;
    height: 25px;
    background-size: 100%;
    border-radius: 3px;
    background-position: center;
    background-color: #fff;
  }
  
@media (max-width: 991.98px) {
  #shopify-section-header-main {
      padding: 10px 0;
  }
  #shopify-section-header-main svg {
  	max-height: 20px;
  }
  .boost-pfs-search-btn {
  	display: none;
  }
  .basket-count {
    right: 0px !important;
  }
}

.blog-header h1 {
  font-size: 36px;
}
.blog-header .breadcrumb a {
  color: rgb(0, 123, 255);
}
.article-container img {
  width: 100%;
}

.article-container p a, .article-container li a,
.collection-content p a, .collection-content li a {
  color: #0000ee !important;
  text-decoration: underline !important;
}

.collec_desc_cst img {
  width: 100%;
  height: auto;
}

.c-table-scroller {
  width: 100%;
  overflow: auto;
}

.product-container h1 {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 100%;
  color: #47444B;
  margin-bottom: 16px;
}

.product-container .c-price-field {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 100%;
  color: #232323;
  padding: 0;
  margin-bottom: 24px;
}

.product-container .instock {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #46444A;
  display: flex;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #c1c1c1;
}

.product-container .instock::before {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: #7BDE39;
  margin: auto 0;
  margin-right: 8px;
}

.product-container .rrp-price {
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: #46444A;
  text-decoration: none;
}

.product-container .rrp-savings {
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: #FF0000;
}

.product-container .condom-secure-text {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #46444A;
}

.product-container .list-items {
  list-style: none;
  font-family: 'Nunito';
  font-style: normal;
  font-size: 16px;
  line-height: 140%;
  color: #46444A;
  margin: 0;
  padding: 0;
}

.product-container .list-items li {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.product-container .list-items li:last-child {
  margin: 0;
}

.product-container .list-items li img {
  height: 32px;
  width: auto;
  margin-right: 10px;
}

.product-container #r1 {
  display: flex;
}

.product-container #AddToCartForm {
  margin-bottom: 24px;
}

.product-container .jdgm-rev {
  background: #FBFBFB;
  border: 1px solid #C1C1C1;
  border-radius: 7px;
  margin-bottom: 40px;
  padding: 12px 18px;
}

.product-container .jdgm-rev .jdgm-rev__timestamp {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #C1C1C1;
  position: absolute;
  right: 18px;
}

.product-container .jdgm-rev .jdgm-rev__body {
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.product-container .jdgm-rev .jdgm-rev__buyer-badge-wrapper {
  display: none;
}

.product-container .jdgm-rev .jdgm-rev__author-wrapper {
  display: none;
}

.product-container .jdgm-rev .jdgm-rev__rating {
  margin-top: 4px;
  display: inline-block;
}

.product-container .jdgm-rev .jdgm-rev__icon {
  width: 32px;
  height: 32px;
  line-height: 32px;
}

.product-container .jdgm-rev .jdgm-rev__icon:after{
  bottom: -4px !important;
  right: -3px !important;
}

.product-container #AddToCartForm button#AddToCart {
  min-height: 48px;
  max-height: 48px;
}

.jdgm-star {
  color: #fbcd0a !important;
}

@media (max-width: 991.98px){
  .product-container .c-price-field {
    margin-bottom: 36px;
  }
  .product-container .instock {
    margin-bottom: 16px;
  }
  .product-container .jdgm-rev {
    margin-bottom: 32px;
  }
  .product-container .r {
    flex-direction: column;
    gap: 6px;
    margin-bottom: 16px;
  }
  .product-container button#AddToCart {
    margin-top: 0px;
    margin-bottom: 16px;
  }
  .product-container #AddToCartForm {
    margin-bottom: 16px;
  }
  .product-container .product-finance-widget {
    margin-bottom: 36px;
  }
  .product-container .condom-secure-box {
    margin-bottom: 36px;
  }
  .product-container .condom-prod-form .bb-cards ul {
    width: 248px;
    flex-wrap: wrap;
    margin: 0 auto;
  }
  .product-container .condom-prod-form .bb-cards .condom-secure-text {
    text-align: center;
  }
  .product-container .condom-prod-form .bb-cards ul li:nth-child(6) {
    margin-left: 25px;
  }
}


.read-more-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin: 0;
}

.read-more-text.read-more-expanded {
  -webkit-line-clamp: unset !important;
  line-clamp: unset !important;
}

.read-more {
  border: none;
  background: transparent;
  color: #0000ee;
  padding: 0;
  margin: 0;
  outline: 0 !important;
}

.ajax-rec-add-to-cart {
  
}

.ajax-item__options label {
  margin-bottom: 6px !important;
}

.ajax-item__options span {
  margin: 0 !important;
}

.boost-pfs-filter-product-item-title {
  font-size: 16px !important;
  line-height: 24px !important;
  margin: 0 !important;
  font-weight: 600 !important;
}

.boost-pfs-filter-product-item-price {
  margin-top: 10px !important; 
}

.collection .boost-pfs-filter-product-bottom {
  min-height: 100px !important;
}

.ajax-item__options {
  justify-content: flex-start !important;
  margin: 10px 0px !important;
}

.ajax-item__option span {
  border-radius: 5px !important;
  font-size: 13px !important;
  line-height: 13px !important;
}

.boost-pfs-filter-label-best-seller {
  background: #FFB932 !important;
  font-size: 14px !important;
  letter-spacing: 0.02em;
  padding: 3px 10px !important;
}

.boost-pfs-filter-label-best-seller svg {
    fill: #fff;
    width: 14px;
    position: relative;
    top: -2px;
    margin-right: 2px;
}

.boost-pfs-filter-product-item-price .money {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.boost-pfs-filter-option-content .boost-pfs-filter-option-item-list.boost-pfs-filter-option-item-list-multiple-list li.selected .boost-pfs-check-box:before {
  border-color: #13B4EF !important;
}
.boost-pfs-filter-option-content .boost-pfs-filter-option-item-list.boost-pfs-filter-option-item-list-multiple-list li .boost-pfs-check-box {
  border-radius: 2px;
}

.boost-pfs-filter-option-content .boost-pfs-filter-option-item-list .boost-pfs-filter-option-item.selected .boost-pfs-filter-option-value {
  font-weight: normal !important;
}

.boost-pfs-filter-tree-content {
  border-radius: 4px;
  background: #FFFFFF;
  padding: 20px;
}

.boost-pfs-filter-options-wrapper .boost-pfs-filter-option:first-child {
  padding-top: 0px !important;
}

.boost-pfs-filter-options-wrapper .boost-pfs-filter-option:last-child {
  padding-bottom: 0px !important;
}

.boost-pfs-filter-option-content {
  padding-top: 10px !important;
}

.boost-pfs-filter-option-title .boost-pfs-filter-clear {
  display: none !important;
}

.boost-pfs-filter-tree-v .boost-pfs-filter-option .boost-pfs-filter-option-title .boost-pfs-filter-option-title-heading {
  width: 100% !important;
}

.boost-pfs-filter-tree-v .boost-pfs-filter-option .boost-pfs-filter-option-title .boost-pfs-filter-option-title-heading {
  padding: 0 !important;
}

.boost-pfs-filter-option-title .boost-pfs-filter-option-title-heading span.boost-pfs-filter-option-title-text {
  display: flex;
  width: 100%;
}

.boost-pfs-filter-tree-v .boost-pfs-filter-option-title-text:before {
  content: "-" !important;
  border: none !important;
  order: 1;
  margin-left: auto !important;
  margin-right: 14px !important;
  color: #13B4EF;
  font-size: 32px;
  font-weight: 400;
}

.boost-pfs-filter-tree-v .boost-pfs-filter-option.boost-pfs-filter-option-collapsed .boost-pfs-filter-option-title-text:before {
  content: "+" !important;
  font-size: 25px;
  font-weight: 600;
  margin-right: 15px!important;
}

.boost-pfs-filter-option.boost-pfs-filter-has-scrollbar .boost-pfs-filter-option-content .boost-pfs-filter-option-content-inner {
  max-height: unset !important;
}

@media (max-width: 767px) {
  .boost-pfs-filter-left-col {
     margin-bottom: 20px;
  }

  .cart-container .cart-totals-wrap {
    margin-bottom: 0 !important;
  }
}

.trustpilot {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-family: TrustpilotDefaultFont, Helvetica, Arial,sans-serif;
}

.trustpilot-mobile {
  gap: 16px;
  padding: 10px 0;
  font-size: 16px;
  color: #000;
  background: #fff;
}

.trustpilot-desktop {
  gap: 16px;
  margin-right: 25px;
  color: #fff;
  font-size: 16px;
  display: none;
}

.trustpilot-desktop .tp-logo__text {
  color: #fff;
  fill: #fff;
}

.trustpilot-desktop svg:nth-child(1) {
  width: 86px;
}

.trustpilot-desktop svg:nth-child(2) {
  width: 72px;
}

afterpay-placement,
klarna-placement {
  display: none;
}


.dynamic-checkout__content {
  padding: 0 20px;
}

.or-pay-by {
    margin-top: 16px;
    text-align: left;
    color: #000;
}

@media (max-width: 767px) {
  #shopify-section-cart-footer {
    margin-top: 40px;   
  }
}

@media (min-width: 767px) {
  #shopify-section-trustpilot {
    display: none;
  }
  .trustpilot-desktop {
    display: flex;
  }
  .cart-container {
    margin-top: 45px;
  }
  .cart .cart-container .cart-totals-wrap .cart-totals {
    flex-direction: column;
    flex-grow: 0;
    max-width: 300px;
    margin-left: auto;
  }
  .dynamic-checkout__content {
    padding: 0;
    margin-top: 16px;
  }
  
}

.list-collections main .container ul li {
  padding: 10px 0;
}

#shopify-section-topbar {
  visibility: visible !important;
}

.cost-per-condom-text {
  font-size: 12px;
}

.collection__description__faq {
  margin-top: 48px;
}

.product-grid-item .jdgm-prev-badge__text {
  display: none;
}

.article main .article-container .blog-main .article-content .author {
  position: relative;
}


.author-popup {
  display: inline-block;
  position: absolute;
  width: 280px;
  min-height: 300px;
  border-radius: 6px;
  box-shadow: 0 0 10px 0px rgba(0,0,0, 0.15);
  background: #fff;
  left: 0;
  top: 25px;
  padding: 24px 18px;
  cursor: initial;
  z-index: 99;
}


@media (max-width: 767px) {
  .author-popup {
    position: fixed;
    inset: 0;
    width: 80vw;
    height: min-content;
    margin: auto;
  }
}

.author-popup-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.author-popup-image {
  border-radius: 100%;
  width: 100px !important;
  height: 100px;
  object-fit: cover;
}

.author-popup-name {
  margin-top: 10px;
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.author-popup-content {
  color: #606060;
  font-size: 14px;
  font-weight: lighter;
}

.author-popup-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.author-popup-footer svg {
  width: 25px;
  height: 25px;
}

.home-as-seen-on {
  margin: 40px 30px;
  margin-top: 10px;
}
.home-as-seen-on h2 {
  text-align: center;
}
.home-as-seen-on .home-as-seen-on-content {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1100px;
  margin: 0 auto;
}
.home-as-seen-on .home-as-seen-on-content img {
  width: 148px;
  height: 60px;
  margin: auto 0;
  object-fit: contain;
}


.article-timeline {
  margin-left: 10px;
  border-left: 1px solid #14b4f0;
  padding-left: 25px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 20px 0;
}

.article-timeline-element {
  position: relative;  
}

.article-timeline-element::after {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #14b4f0;
  top: 8px;
  left: -30.5px;
}

.article-timeline-element-date {
  font-weight: bold;
  font-size: 18px;
}

.article-timeline-element-title {
  font-weight: lighter;
}

.article-timeline-element-name {
  font-size: 14px;
  font-weight: 600;
  color: #14b4f0 !important;
}

.article-timeline-element-name .author {
  color: #14b4f0 !important;
}

.article-head-section {
  margin-bottom: 42px;
}

.article-head-section .article-meta {
  font-weight: lighter;
  margin-bottom: 4px !important;
}

.article-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  min-height: 60px;
  padding: 8px;
  text-align: center;
  background-color: #cdf769;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #272727;
  font-weight: bold;
  z-index: 999999999999;
}

@media screen and (max-width: 990px) {
  .article-banner {
    font-size: 16px;
    display: block;
  }

  .article-banner a {
    display: inline-block;
  }
}

.article-banner > a {
  text-decoration: underline;
}


.country-selector-container {
  display: flex;
  gap: 5px;
  font-size: 14px;
  margin-top: 10px;
}

.country-selector-container .country-selector {
  margin: 0;
}

footer .country-selector-container {
  color: #fff;
}

.mobile-nav .country-selector-container {
  margin-top: 20px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: .04em;
  text-transform: uppercase;
  color: #000;
}

.country-selector {
  background: transparent;
  border: none;
  outline: none !important;
  margin: 10px 0;
  padding: 0;
}

.country-selector-drop {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 999999999999999;
  background: rgba(0 0 0 / 0.7);
}

.country-selector-modal {
  width: 380px;
  height: 320px;
  border-radius: 10px;
  background: #fff;
  padding: 40px;
  box-shadow: 0px 8px 18px 4px rgb(0 0 0 / 0.4);
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  color: #000;
}

.country-selector-modal form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.country-selector-modal select {
  padding: 8px 14px;
  font-size: 16px;
  margin-top: 20px;
  font-weight: 600;
  border: 2px solid #14b4f0;
  border-radius: 6px;
  width: 100%;
}

.country-selector-modal button {
  padding: 10px 14px;
  font-size: 18px;
  margin-top: 20px;
  font-weight: 600;
  border-radius: 6px;
  background: #14b4f0;
  color: #fff;
  width: 100%;
  cursor: pointer;
  border: none;
}


#shopify-section-footer .country-selector {
    display: none;
}
@media (min-width: 992px) {
  #shopify-section-footer .country-selector {
    display: block;
  }
}

@media (min-width: 992px) {
  ul.brands-list {
    gap: 15px;
  } 
}

@media (max-width: 768px) {
  .product-breadcrumbs {
    margin-top: 8px;
    margin-bottom: 12px;
  }

  .boost-pfs-filter-label-best-seller {
    font-size: 10px !important;
    padding: 2px 5px !important;
    padding-left: 3px !important;
    line-height: 110% !important;
  }

  .boost-pfs-filter-label-best-seller svg {
    width: 11px;
    position: relative;
    top: -1px;
  }
}

.product-container #product-carousel {
  margin-bottom: 16px;
}

a.ajax-added-modal-goto-cart .secure-icon {
  border: none;
  width: 18px;
  margin-right: 10px;
  margin-top: -2px;
}

#learn-more-iframe {
  max-height: 80vh !important;
  min-height: 80vh !important;
}